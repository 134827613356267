import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import AppBar from '../../components/AppBar/AppBar';
import LegalFooter from '../../components/Footer/LegalFooter';
import { getPlaceHolderHacks, tan6 } from '../../utils/utils';
import useCriteoScript from '../../hooks/useCriteoScript';
import SEOHelmet from '../../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      position: 'relative',
    },
    topContainer: {
      backgroundColor: '#EA0053',
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      marginBottom: `calc((100vw * ${tan6} * -1) / 2)`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '0 0 25px 50px',
      [theme.breakpoints.up(1006)]: {
        marginBottom: `calc((1006px * ${tan6} * -1) / 2)`,
        paddingBottom: `calc((1006px * ${tan6} * -1) / 2)`,
      },
    },
    topContainerContent: {
      padding: '20px 25px 0',
      transform: 'skewY(6.733deg)',
      maxWidth: 1006,
      margin: '0 auto',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      '& h1': {
        fontSize: '44px',
        lineHeight: '40px',
        marginBottom: '16px',
        color: '#fff',
        textAlign: 'center',
      },
      '& h2': {
        fontSize: '17px',
        lineHeight: '24px',
        color: '#fff',
        textAlign: 'center',
        fontWeight: 400,
        maxWidth: 700,
        margin: '0 auto 60px',
      },
      '& h6': {
        color: '#fff',
        fontSize: 18,
        lineHeight: '21.33px',
        marginBottom: theme.spacing(2),
        wordBreak: 'break-word',
      },
      [theme.breakpoints.up(600)]: {
        alignItems: 'center',
        padding: '80px 50px 0',
        '& h6': {
          fontSize: 18,
          lineHeight: '21.33px',
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: '100px 0 0',
        '& h6': {
          fontSize: 18,
          lineHeight: '21.33px',
          marginBottom: theme.spacing(3),
        },
      },
    },
    articleImage: {
      background: '#ccc',
      margin: '0 0 24px',
      '@media screen and (min-width: 600px)': {
        margin: '0 50px 24px',
      },
      '@media screen and (min-width: 1200px)': {
        margin: 'auto',
        marginBottom: 24,
      },
    },
    voucherContainer: {},
    codeInputContainer: {
      background: '#fff',
      width: '100%',
      maxWidth: 436,
      margin: '0 auto',
      borderRadius: '16px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      padding: '36px',
      marginBottom: '32px',
    },
    voucherText: {
      fontSize: '17px',
      lineHeight: '24px',
      fontWeight: 700,
      margin: '0 0 16px',
      textAlign: 'left',
      color: theme.palette.primary.main,
    },
    noCodeText: {
      fontSize: '17px',
      lineHeight: '24px',
      color: '#fff',
      textAlign: 'center',
      maxWidth: '364px',
      margin: '0 auto',
      whiteSpace: 'pre-wrap',
      '& a': {
        display: 'block',
        color: '#fff',
      }
    },
    promoInputLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    promoInput: {
      // Typography/Body/Body (regular)
      fontFamily: 'Rubik',
      color: theme.palette.text.primary,
      fontSize: 17,
      lineHeight: 1.29,
      fontWeight: 400,
      padding: '8px 32px 8px 0',
      border: 0,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      width: '100%',
      boxSizing: 'border-box',
      backgroundColor: 'unset',
      marginBottom: 24,
      '&:focus-visible': {
        outline: 0,
      },
    },
    button: {
      borderRadius: '0 8px 8px 8px',
      borderTopLeftRadius: 0,
      fontSize: 17,
      lineHeight: '24px',
      padding: '12px 14px',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    buttonOutlined: {
      borderRadius: '0 8px 8px 8px',
      fontSize: 17,
      padding: '12px 14px',
      width: '100%',
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 25px',
      maxWidth: 1440,
      margin: '0 auto',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 22,
        lineHeight: '26.07px',
        fontWeight: 700,
        letterSpacing: -0.5,
        textAlign: 'center',
        marginBottom: 20,
      },
      [theme.breakpoints.up(600)]: {
        padding: '0 50px',
        '& h2': {
          fontSize: 36,
          lineHeight: '42.66px',
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: '0',
        maxWidth: 1006,
        margin: '0 auto',
        '& h2': {
          fontSize: 32,
          lineHeight: '37.92px',
          marginBottom: theme.spacing(6),
        },
      },
    },
    hackCarouselContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up(600)]: {
        marginTop: `calc((100vw * ${tan6}) / 2)`,
        marginBottom: `calc((100vw * ${tan6}) / 4)`,
      },
      [theme.breakpoints.up(1006)]: {
        marginTop: `calc((1006px * ${tan6}) / 2)`,
        marginBottom: `calc((1006px * ${tan6}) / 4)`,
      },
    },
    hackCarouselTitleContainer: {
      [theme.breakpoints.up(600)]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& h2': {
          width: '50%',
          fontSize: 24,
          lineHeight: '28.44px',
          textAlign: 'right',
          marginBottom: theme.spacing(3),
        },
      },
      [theme.breakpoints.up(1200)]: {
        '& h2': {
          width: '50%',
          fontSize: 32,
          lineHeight: '37.92px',
          textAlign: 'right',
        },
      },
    },
    downloadButton: {
      borderRadius: '0 8px 8px',
      fontSize: 17,
      lineHeight: '22px',
      width: 280,
      marginTop: '32px',
      [theme.breakpoints.up(960)]: {
        padding: '10px 10px',
      },
    },
    bottomSectionClipRect: {
      overflow: 'hidden',
    },
    bottomSection: {
      backgroundColor: '#03091D',
      transform: 'skewY(-6.733deg)',
      margin: `calc((100vw * ${tan6} * -1 ) / 2) 0`,
      marginBottom: `calc((100vw * ${tan6}) / 2)`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 0 0',
      [theme.breakpoints.up(1006)]: {
        paddingTop: `calc((1006px * ${tan6} * -1) / 2)`,
      },
      [theme.breakpoints.up(2080)]: {
        paddingTop: `calc((90vw * ${tan6} * -1) / 2)`,
      },
    },
    bottomSectionContent: {
      padding: '20px 25px',
      transform: 'skewY(6.733deg)',
      textAlign: 'center',
      height: '60px',
      '& h2': {
        color: '#fff',
        fontSize: 24,
        lineHeight: '50px',
        letterSpacing: -0.5,
      },
      '& h6': {
        color: '#fff',
        fontSize: 18,
        lineHeight: '21.33px',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up(600)]: {
        padding: '20px 50px',
        '& h2': {
          fontSize: 36,
          marginBottom: theme.spacing(),
        },
        '& h6': {
          marginBottom: theme.spacing(6),
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: 0,
        maxWidth: 1006,
        margin: '0 auto',
        '& h2': {
          fontSize: 32,
          lineHeight: '50px',
          marginBottom: theme.spacing(),
        },
        '& h6': {
          fontSize: 16,
          lineHeight: '18.96px',
          marginBottom: theme.spacing(6),
        },
      },
    },
    bottomSectionDownloadButtons: {
      '& a': {
        display: 'block',
      },
      '& img': {
        marginBottom: 25,
        height: 50,
      },
      [theme.breakpoints.up(600)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& a': {
          marginRight: 20,
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
  };
});

const placeholderHacks = getPlaceHolderHacks();

const MarketingCampaign = ({ data, location, pageContext }) => {
  const node = data?.prismicMarketingCampaign;
  const classes = useStyles();
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState('');
  const inputId = 'code-input';
  const brandName = node?.data?.brand_name?.text;

  const { trackCriteoButtonClick } = useCriteoScript();

  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar overrideButtons={<LanguageSwitcher />} maxWidth="1054px" removeLogoLink disableBurgerMenu keepBackgroundColor />
      <main className={classes.root}>
        <div className={classes.topContainer}>
          <div className={classes.topContainerContent}>
            <Typography variant="h1">{t('marketingCampaignPage.title', { brandName })}</Typography>
            <Typography variant="h2">{node?.data?.title?.text}</Typography>
            <div className={classes.voucherContainer}>
              <div className={classes.codeInputContainer}>
                <p className={classes.voucherText}>
                  {t('marketingCampaignPage.voucherText', { brandName })}
                </p>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <input
                    id={inputId}
                    type="text"
                    className={classes.promoInput}
                    placeholder="Enter promo code"
                    value={promoCode}
                    onChange={e => {
                      setPromoCode(e.target.value);
                    }}
                  />
                  <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={promoCode.length < 1}
                    href={`https://web.uptime.app/login/marketing?marketingCode=${promoCode}${brandName && `&brandName=${brandName}`}`}
                  >
                    {node?.data?.redeem_button_label?.text}
                  </Button>
                </form>
              </div>
              <p className={classes.noCodeText}>
                {t('marketingCampaignPage.noCodeText')}
                <a href="https://web.uptime.app/login">
                  {t('marketingCampaignPage.noCodeLinkText')}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.bottomSectionClipRect}>
          <div className={classes.bottomSection}>
            <div className={classes.bottomSectionContent}>
            </div>
          </div>
        </div>
      </main>
      <LegalFooter />
    </>
  );
};

export default withPrismicPreview(MarketingCampaign);

export const query = graphql`
  query MarketingCampaignQuery($id: String, $language: String) {
    prismicMarketingCampaign(id: { eq: $id }) {
      _previewable
      id
      prismicId
      data {
        brand_name {
          text
        }
        title {
          text
        }
        promo_code_input_label {
          text
        }
        redeem_button_label {
          text
        }
        no_code_title {
          text
        }
        no_code_subtitle {
          text
        }
        free_trial_button_label {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
    prismicLandingV3 {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        library_title {
          text
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
