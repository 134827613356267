/* eslint-disable react/button-has-type */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => {
  return {
    default: {
      display: 'flex',
      backgroundColor: ({ variant }: { variant: string }) =>
        variant === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
      borderRadius: '0px 8px 8px 8px',
      width: 'auto',
      padding: '12px 24px',
      border: 0,
      cursor: 'pointer',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Rubik',
      justifyContent: 'center',
      fontSize: '17px',
      lineHeight: '20px',
      textDecoration: 'none',
      transition: 'background-color 0.2s ease-in',
      '&:hover': {
        backgroundColor: ({ variant }: { variant: string }) => (variant === 'primary' ? '#2B355B' : '#D1004A'),
      },
    },
    secondary: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      minWidth: '129px',
      width: 'auto',
      padding: '12px 24px',
      height: '46px',
      backgroundColor: 'unset',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
      borderRadius: '0px 8px 8px 8px',
      border: `2px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontFamily: 'Rubik',
      justifyContent: 'center',
      fontSize: '17px',
      lineHeight: '20px',
      transition: 'background-color 0.2s ease-in',
      '&:focus': {
        borderWidth: '4px',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      '&:active': {
        backgroundColor: '#313852',
        borderColor: '#313852',
        color: '#fff',
      },
    },
  };
});

type ButtonType = {
  children: any;
  variant: string;
  className?: any;
  type?: 'button' | 'reset' | 'submit';
  to?: string;
  [x: string]: any;
};

export const DefaultButton = ({ children, variant, className, to, type = 'button', ...rest }: ButtonType) => {
  const classes = useStyles({ variant });
  return to ? (
    <Link className={classnames(classes.default, className)} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <button type={type} className={classnames(classes.default, className)} {...rest}>
      {children}
    </button>
  );
};

export const SecondaryButton = ({ children, variant, className, to, type = 'button', ...rest }: ButtonType) => {
  const classes = useStyles({ variant });
  return to ? (
    <Link className={classnames(classes.secondary, className)} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <button type={type} className={classnames(classes.secondary, className)} {...rest}>
      {children}
    </button>
  );
};
