import React from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PrismicRichText } from '@prismicio/react';
import CloseIcon from '../icons/CloseIcon';

const useStyles = makeStyles((theme) => {
  return {
    modalWrapper: {
      padding: '50px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 20,
      paddingTop: '6px',
    },
    content: {
      padding: '20px 25px',
    },
    closeButtonWrapper: {
      justifyContent: 'flex-end',
    },
    closeButton: {
      color: theme.palette.primary.main,
      fontSize: 18,
      marginBottom: '10px',
    },
    paragraph: {
      marginBottom: '14px',
    },
    footerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    acceptButtonBottom: {
      fontSize: 18,
      color: theme.palette.secondary.main,
    },
    closeButtonBottom: {
      fontSize: 18,
    },
  };
});

const Modal = ({ isOpen, onClose, title, body, onAccept, hideAccept }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <Button className={classes.closeButtonWrapper} variant="text" onClick={onClose}>
            <CloseIcon className={classes.closeButton} />
          </Button>
        </div>
        <div className={classes.footnote}>
          <PrismicRichText field={body} />
        </div>
        <div className={classes.footerWrapper}>
          {!hideAccept && (
            <Button
              className={classes.acceptButtonBottom}
              variant="text"
              onClick={() => {
                onAccept();
                onClose();
              }}
            >
              Accept
            </Button>
          )}
          <Button className={classes.closeButtonBottom} variant="text" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
