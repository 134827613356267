import * as Yup from 'yup';

export const BusinessEnquiryFormSchema = Yup.object().shape({
  'name': Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  'company-name': Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  'number-of-customers': Yup.number().required('Required'),
  'business-email': Yup.string()
    .email('Invalid email')
    .required('Required'),
  'contact-number': Yup.string()
    .min(10, 'Too Short!')
    .max(16, 'Too Long!')
    .required('Required'),
});

export const businessEnquiryFormFields = [
  { id: 'name', labelText: 'Your Name', type: 'text' },
  { id: 'company-name', labelText: 'Company Name', type: 'text' },
  { id: 'number-of-customers', labelText: 'Number of customers', type: 'number' },
  { id: 'business-email', labelText: 'Business Email', type: 'text' },
  { id: 'contact-number', labelText: 'Contact number', type: 'text' },
];
