import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { BodyRegular, H1 } from '../Typography/Typography';
import useCriteoScript from '../../hooks/useCriteoScript';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: '#fff',
      padding: '40px 24px 0',
      [theme.breakpoints.up(1000)]: {
        display: 'flex',
        flexDirection: 'row',
        padding: '40px 0 0',
        position: 'relative',
      },
    },
    inner: {
      position: 'relative',
      [theme.breakpoints.up(1000)]: {
        width: '100%',
        maxWidth: '1218px',
        margin: '0 auto',
        padding: '24px 24px 108px',
      },
    },
    left: {
      [theme.breakpoints.up(1000)]: {
        width: '36%',
      },
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '24px',
      '& a': {
        marginRight: '6px',
        '&:last-child': {
          marginRight: 0,
        },
      },
      '& img': {
        display: 'block',
        height: '47px',
      },
    },
    awards: {
      display: 'block',
      height: '45px',
      [theme.breakpoints.up(1000)]: {
        height: '55px',
      }
    },
    phonesContainer: {
      position: 'relative',
      width: '100%',
      height: '238px',
      marginTop: '40px',
      [theme.breakpoints.up(1000)]: {
        display: 'none',
      },
    },
    phones: {
      position: 'absolute',
      right: -24,
      width: '361px',
    },
    phonesMd: {
      display: 'none',
      [theme.breakpoints.up(1000)]: {
        display: 'block',
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '90%',
      },
      [theme.breakpoints.up(1060)]: {
        height: '100%',
      },
    },
    bodyRegular: {
      marginBottom: '16px',
    },
  };
});

type DownloadComponent = {
  className?: any;
  showPhones?: boolean;
};

const DownloadComponent = ({ className, showPhones }: DownloadComponent) => {
  const { trackCriteoButtonClick } = useCriteoScript();
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.inner}>
        <div className={classes.left}>
          <H1>{t('downloadComponent.title')}</H1>
          <BodyRegular className={classes.bodyRegular}>
            {t('downloadComponent.text')}
          </BodyRegular>
          <div className={classes.buttonsContainer}>
            <a
              onClick={trackCriteoButtonClick}
              href="https://uptime.app.link/S5iL3GrK0cb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={`/img/download-component/app_store_badge_${language}.svg`} alt="" />
            </a>
            <a
              onClick={trackCriteoButtonClick}
              href="https://uptime.app.link/S5iL3GrK0cb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={`/img/download-component/google_play_badge_${language}.svg`} alt="" />
            </a>
          </div>
          <img className={classes.awards} src={`/img/download-component/uptime_accolades_${language}.svg`} alt="" />
        </div>
        {showPhones && (
          <>
            <div className={classes.phonesContainer}>
              <img className={classes.phones} src={`/img/download-component/uptime_screens_${language}.png`} alt="" />
            </div>
            <img className={classes.phonesMd} src={`/img/download-component/uptime_screens_${language}.png`} alt="" />
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadComponent;
