import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <rect width="31.1434" height="2.59529" rx="1.29764" transform="matrix(0.703988 0.710212 -0.703988 0.710212 2.07544 0)" />
      <rect width="31.1434" height="2.59529" rx="1.29764" transform="matrix(0.703988 -0.710212 0.703988 0.710212 0 22.1568)" />
    </SvgIcon>
  )
}

export default CloseIcon;
