import algoliasearch from 'algoliasearch';
import moment from 'moment';
import { getWebsiteLinkForHack } from './utils';

export type getHacksArgs = {
  filter?: string;
  setHacks: (hacks: any[]) => void;
  placeholderHacks: any[];
  language?: string;
};

const getHacksIndex = () => {
  const algoliaAppId = process.env.GATSBY_STAGE === 'prod' ? process.env.GATSBY_ALGOLIA_APP_ID : process.env.GATSBY_ALGOLIA_APP_ID_STAGING;
  const algoliaApiKey = process.env.GATSBY_STAGE === 'prod' ? process.env.GATSBY_ALGOLIA_API_KEY : process.env.GATSBY_ALGOLIA_API_KEY_STAGING;
  const client = algoliasearch(algoliaAppId, algoliaApiKey);
  const index = client.initIndex(`${process.env.GATSBY_STAGE}-website-hacks`);
  return index;
}

export const getHacks = async ({
  filter,
  setHacks,
  placeholderHacks,
  language = 'en',
}: getHacksArgs) => {
  const index = getHacksIndex();
  const today = moment()
    .startOf('day')
    .valueOf();
  try {
    const { hits } = await index.search('', {
      filters: filter ? `type:${filter} AND editorialTags:top AND language:${language}` : `language:${language}`,
      numericFilters: `createdAtTimestamp<${today}`,
    });
    setHacks(
      hits.map(hack => {
        return {
          ...hack,
          branchLink: getWebsiteLinkForHack(hack),
        };
      }),
    );
  } catch (err) {
    // handle algolia errors here
    setHacks(placeholderHacks);
  }
};

export const getCreatorHacks = async ({
  topic,
  setHacks,
  hackIds,
  language = 'en',
}) => {
  const index = getHacksIndex();
  const today = moment()
    .startOf('day')
    .valueOf();
  let hits = [];
  try {
    if (hackIds.length > 0) {
      const res = await index.getObjects(hackIds);
      hits = res.results.filter((result) => Boolean(result));
    } else {
      const res = await index.search('', {
        filters: topic ? `topics.name:"${topic}" AND editorialTags:top AND language:${language}` : `language:${language}`,
        numericFilters: `createdAtTimestamp<${today}`,
        hitsPerPage: 3,
      });
      hits = res.hits;
    }
    setHacks(
      hits.map(hack => {
        return {
          ...hack,
          branchLink: getWebsiteLinkForHack(hack),
        };
      }),
    );
  } catch (err) {
    // handle algolia errors here
    setHacks([]);
  }
};
