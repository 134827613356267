import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      zIndex: 2,
      backgroundColor: '#03091D',
      color: '#fff',
    },
    inner: {
      maxWidth: '600px',
      width: '100%',
      margin: '0 auto',
      padding: '40px 24px',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '80px 24px 40px',
      },
    },
    gridItem: {
      marginBottom: '40px',
      '&:last-child': {
        marginBottom: '0',
      },
      [theme.breakpoints.up(1008)]: {
        marginBottom: '0',
      },
    },
    disclaimerContainer: {
      margin: '0 auto 48px',
      width: 'fit-content',
    },
    title: {
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: '500',
      marginBottom: 12,
    },
    link: {
      width: '100%',
      fontSize: 16,
      lineHeight: '32px',
      textDecoration: 'none',
      color: '#fff',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      marginTop: '24px',
      opacity: '0.4',
      gap: '12px',
      '& a': {
        display: 'block',
      },
      '@media screen and (min-width: 600px)': {
        width: 'unset',
      },
    },
    copyright: {
      display: 'block',
      fontSize: 13,
      lineHeight: '16px',
      fontWeight: '500',
      marginBottom: '8px',
      '@media screen and (min-width: 600px)': {
        fontSize: 16,
      },
    },
    disclaimer: {
      display: 'block',
      fontSize: 10,
      lineHeight: '14px',
    },
  };
});

const Footer = ({
  paddingTop,
}: { paddingTop?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useI18next();
  const lang = language.startsWith('en') ? 'en' : language;
  const now = new Date();
  const getInTouchLinks = t('footer.getInTouch.links').split('|');
  const getStartedLinks = t('footer.getStarted.links').split('|');
  const aboutUsLinks = t('footer.aboutUs.links').split('|');
  const navigationLinks = t('footer.navigation.links').split('|');
  return (
    <footer className={classes.root}>
      <div className={classes.inner} style={{ paddingTop: paddingTop }}>
        <div className={classes.gridItem}>
          <Typography className={classes.title} variant="h5">
            {t('footer.getInTouch.title')}
          </Typography>
          <a href="mailto:info@uptime.app" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getInTouchLinks[0]}
            </Typography>
          </a>
          <a href="mailto:support@uptime.app" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getInTouchLinks[1]}
            </Typography>
          </a>
          <Link to="/creators" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getInTouchLinks[2]}
            </Typography>
          </Link>
          <a href="mailto:b2b@uptime.app" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getInTouchLinks[3]}
            </Typography>
          </a>
        </div>
        <div className={classes.gridItem}>
          <Typography className={classes.title} variant="h5">
            {t('footer.getStarted.title')}
          </Typography>
          <a href="https://web.uptime.app" target="_blank" rel="noopener noreferrer" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getStartedLinks[0]}
            </Typography>
          </a>
          <a href="https://web.uptime.app/login/standalone" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getStartedLinks[1]}
            </Typography>
          </a>
          <Link to="/gifting" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getStartedLinks[2]}
            </Typography>
          </Link>
          <a href={`https://help.uptime.app/${lang}/`} target="_blank" rel="noopener noreferrer" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {getStartedLinks[3]}
            </Typography>
          </a>
        </div>
        <div className={classes.gridItem}>
          <Typography className={classes.title} variant="h5">
            {t('footer.aboutUs.title')}
          </Typography>
          <a href="/press_page/" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[0]}
            </Typography>
          </a>
          <a
            href="https://uptime.bamboohr.com/jobs/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[1]}
            </Typography>
          </a>
          <Link to="/terms-and-conditions" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[2]}
            </Typography>
          </Link>
          <Link to="/privacy-policy" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[3]}
            </Typography>
          </Link>
          <Link to="/cookie-policy" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[4]}
            </Typography>
          </Link>
          <a href="/sitemap-index.xml" className={classes.link}>
            <Typography className={classes.link} variant="body1">
              {aboutUsLinks[5]}
            </Typography>
          </a>
        </div>
        <div className={classes.gridItem}>
          <Typography className={classes.title} variant="h5">
            {t('footer.navigation.title')}
          </Typography>
          <Link className={classes.link} to="/blog">
            <Typography className={classes.link} variant="body1">
              {navigationLinks[0]}
            </Typography>
          </Link>
          <Link className={classes.link} to="/hacks">
            <Typography className={classes.link} variant="body1">
              {navigationLinks[1]}
            </Typography>
          </Link>
          <Link className={classes.link} to="/collections">
            <Typography className={classes.link} variant="body1">
              {navigationLinks[2]}
            </Typography>
          </Link>
          <div className={classes.iconContainer}>
            <a
              className={classes.followLink}
              href="https://www.instagram.com/uptime.app/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/img/instagram.svg" alt="Instagram" />
            </a>
            <a
              className={classes.followLink}
              href="https://twitter.com/uptimeapp?lang=en"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/img/twitter.svg" alt="Twitter" />
            </a>
            <a
              className={classes.followLink}
              href="https://www.facebook.com/Uptime-109826557071160/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/img/facebook.svg" alt="Facebook" />
            </a>
            <a
              className={classes.followLink}
              href="https://www.linkedin.com/company/uptime.app/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="/img/linkedin.svg" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.disclaimerContainer}>
        <span className={classes.copyright}>&copy; {now.getFullYear()} {t('footer.copyright')}</span>
        <span className={classes.disclaimer}>{t('footer.disclaimer')}</span>
      </div>
    </footer>
  );
};

export default Footer;
