import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import HeroContainer from '../components/HeroContainer/HeroContainer';
import { DefaultButton } from '../components/Button/Button';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { QuoteBox, TwitterBox } from '../components/QuoteBox';
import { tan6 } from '../utils/utils';
import { BodyRegular, H1, H2, H3 } from '../components/Typography/Typography';
import classnames from 'classnames';
import CreatorsHackList from '../components/CreatorsHackList/CreatorsHackList';
import CreatorsTestimonialSection from '../components/CreatorsTestimonialSection/CreatorsTestimonialSection';
import HowItWorksSteps from '../components/HowItWorksSteps/HowItWorksSteps';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import IframeResizer from 'iframe-resizer-react';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      flex: '1 0 auto',
      '& > div > div': {
        justifyContent: 'space-between',
        padding: '34px 24px 22px',
        marginBottom: '-100px',
        [theme.breakpoints.up(1200)]: {
          padding: '76px 24px 57px',
          marginBottom: '-192px',
          alignItems: 'flex-start',
        },
      },
    },
    logo: {
      width: 130,
      [theme.breakpoints.up(660)]: {
        width: 160,
      },
    },
    heroTitleText: {
      fontSize: '24px !important',
      fontWeight: 700,
      lineHeight: '30px !important',
      color: '#fff',
      margin: '0 !important',
      [theme.breakpoints.up(660)]: {
        fontSize: '41px !important',
        margin: '0 0 10px 0 !important',
      },
    },
    headerButtonWrapper: {
      display: 'flex',
      gap: '8px',
    },
    heroButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    signInFootnote: {
      color: '#fff',
      fontSize: '12px',
      lineHeight: '16px',
    },
    signInFootnoteLink: {
      color: '#fff',
      textDecoration: 'underline',
      fontWeight: '700',
    },
    mainDescription: {
      maxWidth: '390px',
      lineHeight: '24px',
      margin: '16px 0 32px',
      fontWeight: '400',
    },
    downloadButton: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 15,
      lineHeight: '22px',
      fontWieght: 700,
      padding: '12px 18px',
      borderRadius: '0px 8px 8px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    hacksContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '960px',
      width: '100%',
    },
    hacksTitle: {
      maxWidth: '854px',
      whiteSpace: 'pre-line',
      textAlign: 'center',
      marginBottom: '16px',
      fontSize: '23px',
      lineHeight: '33px',
      fontWeight: '500',
      [theme.breakpoints.up(1200)]: {
        fontSize: '27px',
        lineHeight: '36px',
        fontWeight: '700',
      }
    },
    hacksSubtitle: {
      fontSize: '17px',
      lineHeight: '24px',
      textAlign: 'center',
      fontWeight: '400',
      color: '#4F4F4F',
      margin: '0 0 40px',
      maxWidth: '683px',
      [theme.breakpoints.up(1200)]: {
        fontSize: '21px',
        lineHeight: '30px',
        fontWeight: '500',
        margin: '0 0 72px',
      },
    },
    heroImage: {
      width: '397px',
      maxWidth: '100%',
      height: '100%',
      [theme.breakpoints.up(1200)]: {
        width: '520px',
        height: '100%',
      },
    },
    heavyLiftingImageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(1200)]: {
        padding: '20px 0',
      },
    },
    heavyLiftingImage: {
      width: '332px',
      [theme.breakpoints.up(1200)]: {
        width: '432px',
      },
    },
    subHeading: {
      display: 'inline-block',
      marginBottom: '10px',
    },
    heroContainerContent: {
      alignItems: 'center',
    },
    heroTextContainer: {
      [theme.breakpoints.up(1200)]: {
        marginTop: '40px',
      },
    },
    logosWrapper: {
      marginBottom: '24px',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '0px',
      [theme.breakpoints.up(1200)]: {
        marginTop: '60px',
        padding: '0 40px',
      },
    },
    iconText: {
      textAlign: 'center',
      marginTop: '20px',
    },
    featuredText: {
      color: '#fff',
      maxWidth: '480px',
      margin: '0 0 20px 0',
      [theme.breakpoints.up(1200)]: {
        margin: '0 0 40px 0',
      },
    },
    featuredTopWrapper: {
      [theme.breakpoints.up(1200)]: {
        paddingBottom: '40px !important',
      },
      paddingBottom: '30px !important',
    },
    quotesIntroWrapper: {
      margin: '20px 0 40px',
      [theme.breakpoints.up(1200)]: {
        margin: '60px 0',
      },
    },
    catalogText: {
      color: '#fff',
      marginBottom: '20px',
      [theme.breakpoints.up(1200)]: {
        marginBottom: '40px',
      },
    },
    featuredWork: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
    },
    whiteText: {
      color: '#fff',
    },
    moreStats: {
      maxWidth: '640px',
      textAlign: 'center',
      margin: '20px 0',
      [theme.breakpoints.up(1200)]: {
        margin: '10px 0 30px',
      },
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '48px 24px 0',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1218px',
      },
    },
    appAwards: { maxWidth: '90%', width: '354px' },
    headerButton: {
      width: 'auto',
    },
    heroButton: {
      width: 'auto',
      marginBottom: '48px',
    },
    factWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& h1': {
        marginBottom: '8px',
      },
      '& p': {
        margin: '0px',
        marginBottom: '20px',
        [theme.breakpoints.up(1200)]: {
          marginBottom: '50px',
        },
      },
      marginTop: '0px',
      [theme.breakpoints.up(1200)]: {
        marginTop: '30px',
      },
      padding: '0 20px',
    },
    whereWeGo: {
      backgroundColor: '#1F2744',
      padding: '10px 0',
      marginTop: '40px',
      [theme.breakpoints.up(1200)]: {
        marginTop: '70px',
        padding: '70px 0',
      },
    },
    bulletPointWrapper: {
      padding: 0,
      margin: 0,
    },
    bulletPoint: {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'baseline',
      gap: '18px',
      margin: '0 0 30px',
      padding: 0,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    bulletPointText: {
      color: theme.palette.text.primary,
      margin: 0,
    },
    sectionNavy: {
      backgroundColor: theme.palette.primary.main,
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      marginBottom: '32px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 25px 50px',
      [theme.breakpoints.up(1170)]: {
        marginBottom: `calc((1170px * ${tan6} * -1) / 2)`,
        marginTop: `calc((1170px * ${tan6} * -1) / 2)`,
        padding: `calc((1170px * ${tan6} * -1) / 2)`,
      },
    },
    sectionNavyContent: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '46px 24px',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1170px',
      },
    },
    checklistWrapper: {
      margin: '30px 0 0px',
      [theme.breakpoints.up(1200)]: {
        margin: '70px 0 0px',
      },
    },
    checklistWrapperSecond: {
      margin: '0px 0 20px',
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column-reverse',
        margin: '0px 0 40px',
      },
    },
    companiesLogo: {
      [theme.breakpoints.up(1200)]: {
        marginTop: '10px',
      },
    },
    beFeaturedImageWrapper: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      justifyContent: 'center',
    },
    beFeaturedImage: {
      maxWidth: '1440px',
    },
    accreditionImage: {
      marginBottom: '20px',
    },
    whereWeGoHeader: {
      color: '#fff',
      marginBottom: '40px',
      [theme.breakpoints.up(1200)]: {
        marginBottom: '70px',
      },
    },
    finalQuotes: {
      margin: '20px 0 10px',
      width: '100%',
      [theme.breakpoints.up(1200)]: {
        margin: '30px 0',
      },
    },
    accreditationWrapper: {
      margin: '30px 0',
      '& img': {
        maxWidth: '100%',
      },
    },
    appStarsBoxWrapper: {
      display: 'flex',
    },
    appStarsBox: {
      padding: '32px 82px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      borderRadius: '16px',
      marginBottom: '20px',
      [theme.breakpoints.up(1200)]: {
        marginBottom: '40px',
        marginRight: '24px',
      },
    },
    processText: {
      [theme.breakpoints.up(1200)]: {
        padding: '0 50px',
      },
      padding: '0 10px',
    },
    stepsImageWrapper: {
      '& img': {
        maxWidth: '100%',
      },
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '80px',
      [theme.breakpoints.down(600)]: {
        marginBottom: '40px',
        display: 'none',
      },
    },
    stepsMobileImageWrapper: {
      '& img': {
        maxWidth: '100%',
      },
      display: 'none',
      justifyContent: 'center',
      marginBottom: '80px',
      [theme.breakpoints.down(600)]: {
        marginBottom: '40px',
        display: 'flex',
      },
    },
    howItWorksContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up(1200)]: {
        flexDirection: 'row-reverse',
      }
    },
    howItWorksImage: {
      width: '276px',
      height: '596px',
      boxSizing: 'content-box',
      border: '4.76px solid #FFF',
      borderRadius: '29.29px',
      boxShadow: '0px 8.03244px 32.1298px rgba(0, 0, 0, 0.1)',
      marginBottom: '40px',
      objectFit: 'none',
      [theme.breakpoints.up(1200)]: {
        flexShrink: '0',
      }
    },
    caveatTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '40px',
      [theme.breakpoints.up(1200)]: {
        marginBottom: '0',
        marginRight: '32px',
      },
    },
    arrow: {
      display: 'block',
      width: '46px',
      marginRight: '40px',
      alignSelf: 'flex-end',
      [theme.breakpoints.up(1200)]: {
        transform: 'rotate(94deg)',
        marginRight: '0',
        alignSelf: 'center',
      },
    },
    caveatText: {
      display: 'block',
      fontFamily: 'Caveat, sans-serif',
      fontSize: '24px',
      lineHeight: '24px',
      textAlign: 'center',
      fontWeight: '700',
      color: theme.palette.primary.main,
      margin: 0,
      width: '166px',
    },
    caveatHeroContainer: {
      position: 'absolute',
      display: 'none',
      top: '-75px',
      left: '-25px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '40px',
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
      }
    },
    arrowHero: {
      display: 'block',
      width: '32px',
      transform: 'rotate(-8deg)',
    },
    caveatTextHero: {
      display: 'block',
      fontFamily: 'Caveat, sans-serif',
      fontSize: '24px',
      lineHeight: '24px',
      textAlign: 'center',
      fontWeight: '700',
      color: '#FFF',
      margin: 0,
      width: '222px',
    }
  };
});

const CreatorsPage = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const pageData = data?.prismicCreatorLandingPageV2?.data;
  const yourHackCover = getImage(pageData?.your_hack_cover);
  const videoRef = useRef(null);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    }
  }, [videoRef, options]);

  return (
    <>
      <SEOHelmet
        title={t('creatorsPage.seo.title')}
        description={t('creatorsPage.seo.description')}
        keywords={t('creatorsPage.seo.keywords').split(',').map((s) => ({ text: s }))}
        location={location}
        image={pageData?.meta_image}
        schemaKey={pageData?.id}
        locale={pageContext?.language}
      />
      <AppBar
        logoPath="/img/creators/logo-creators.svg"
        logoClass={classes.logo}
        overrideButtons={
          <div className={classes.headerButtonWrapper}>
            <DefaultButton
              rel="noopener noreferrer"
              target="_blank"
              to="https://web.uptime.app/login/standalone?signIn=true&creator=true"
              variant="primary"
              className={classes.headerButton}
            >
              {t('creatorsPage.signInText')}
            </DefaultButton>
            <DefaultButton
              rel="noopener noreferrer"
              target="_blank"
              to={pageData.page_button_link.text}
              variant="primary"
              className={classes.headerButton}
            >
              {t('creatorsPage.buttonText')}
            </DefaultButton>
          </div>
        }
      />
      <main className={classes.root}>
        <HeroContainer classes={classes}>
          <div className={classes.heroTextContainer}>
            {pageData.hero_titles?.map(({ title_item }, index) => (
              <h1 key={title_item?.text} className={classes.heroTitleText}>
                {t(`creatorsPage.heroTitles.${index + 1}.text`)}
              </h1>
            ))}
            <BodyRegular className={classnames(classes.whiteText, classes.mainDescription)}>
              {pageData.main_description?.text}
            </BodyRegular>
            <div className={classes.logosWrapper}>
              <img className={classes.appAwards} src="/img/creators/accreditation-1.svg" alt={t('creatorsPage.accreditation1.alt')} />
            </div>
            <div className={classes.heroButtonWrapper}>
              <DefaultButton
                rel="noopener noreferrer"
                target="_blank"
                to={pageData.page_button_link.text}
                variant="primary"
                className={classes.heroButton}
                style={{ marginBottom: 0 }}
              >
                {t('creatorsPage.buttonText')}
              </DefaultButton>
              <p className={classes.signInFootnote}>
                <Trans
                  i18nKey="creatorsPage.signInFootnote"
                  components={{
                    l1: (
                      <a className={classes.signInFootnoteLink} href="https://web.uptime.app/login/standalone?signIn=true&creator=true" target="_blank" rel="noopener noreferrer" />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <img className={classes.heroImage} src={pageData?.hero_image.url} alt={pageData?.hero_image.alt || ''} />
          </div>
        </HeroContainer>
        <section className={classes.sectionContent}>
          <div className={classes.hacksContainer}>
            <H2 className={classes.hacksTitle}>{t('creatorsPage.hacks.title')}</H2>
            <p className={classes.hacksSubtitle}>{t('creatorsPage.hacks.subtitle')}</p>
            <CreatorsHackList
              placeholder={{
                cover: yourHackCover,
                title: t('creatorsPage.yourHack.title'),
                author: t('creatorsPage.yourHack.author'),
              }}
              hacks={{
                'Personal Growth': pageData?.personal_growth_hacks?.map(({ hack_id }) => hack_id.text),
                'Business': pageData?.business_hacks?.map(({ hack_id }) => hack_id.text),
                'Leadership & Entrepreneurship': pageData?.leadership_hacks?.map(({ hack_id }) => hack_id.text),
                'Health & Fitness': pageData?.health_and_fitness_hacks?.map(({ hack_id }) => hack_id.text),
                'Culture & Society': pageData?.culture_and_society_hacks?.map(({ hack_id }) => hack_id.text),
                'Career & Success': pageData?.career_and_success_hacks?.map(({ hack_id }) => hack_id.text),
                'Parenting & Relationships': pageData?.parenting_and_relationships_hacks?.map(({ hack_id }) => hack_id.text),
              }}
            />
            <DefaultButton
              target="_blank"
              to={pageData.page_button_link.text}
              variant="secondary"
              className={classes.heroButton}
            >
              {t('creatorsPage.buttonText')}
            </DefaultButton>
          </div>
        </section>
        <CreatorsTestimonialSection testimonials={pageData.creator_testimonial} awardsImage={pageData.testimonials_awards_image} />
        <section className={classes.sectionContent}>
          <H1>{t('creatorsPage.howItWorks.title')}</H1>
          <div className={classes.howItWorksContainer}>
            <HowItWorksSteps steps={pageData.how_it_works_steps} />
            <video
              ref={videoRef}
              className={classes.howItWorksImage}
              src={pageData?.how_it_works_video?.url}
              poster={pageData?.how_it_works_video_poster?.url}
              onClick={(e) => {
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
              preload="metadata"
              playsInline
              loop
              muted
            />
            <div className={classes.caveatTextContainer}>
              <img className={classes.arrow} src="/img/creators/arrow.svg" alt="" />
              <span className={classes.caveatText}>
                {t('creatorsPage.caveatText')}
              </span>
            </div>

          </div>
        </section>
        <section className={classes.sectionNavy}>
          <div className={classes.sectionNavyContent}>
            <div className={classes.featuredWork}>
              <H1 className={classes.whiteText}>{t('creatorsPage.approach.title')}</H1>
              <Grid container spacing={2}>
                {pageData.approach_steps.map(({ step_text }, i) => (
                  <Grid key={`${step_text?.text}-${i}`} item xs={12} sm={4}>
                    <div className={classes.factWrapper}>
                      <BodyRegular className={classes.featuredText}>{t(`creatorsPage.approach.steps.${i + 1}.text`)}</BodyRegular>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <div>
                <DefaultButton
                  target="_blank"
                  to={pageData.page_button_link.text}
                  variant="secondary"
                  className={classes.heroButton}
                >
                  {t('creatorsPage.buttonText')}
                </DefaultButton>
              </div>
            </div>
            <GatsbyImage
              className={classes.companiesLogo}
              image={pageData.approach_image.gatsbyImageData as IGatsbyImageData}
              alt={pageData?.approach_image.alt || ''}
            />
          </div>
        </section>
        <section className={classes.sectionContent}>
          <H1>{t('creatorsPage.audience.title')}</H1>
          <BodyRegular className={classes.moreStats}>{t('creatorsPage.audience.description')}</BodyRegular>
          <Grid container spacing={2}>
            {pageData.audience_steps.map(({ step_description, step_title }, i) => (
              <Grid key={`${step_description.text}-${i}`} item xs={12} sm={4}>
                <div className={classes.factWrapper}>
                  <H1>{t(`creatorsPage.audience.steps.${i + 1}.title`)}</H1>
                  <BodyRegular className={classes.iconText}>{t(`creatorsPage.audience.steps.${i + 1}.text`)}</BodyRegular>
                </div>
              </Grid>
            ))}
          </Grid>
          <div className={classes.finalQuotes}>
            <IframeResizer
              src="https://widget.senja.io/widget/32f4d658-1e72-47f2-bf44-1fb741a217bd"
              style={{ width: '1px', minWidth: '100%', border: 0 }}
            />
          </div>
          <div className={classes.accreditationWrapper}>
            <GatsbyImage
              className={classes.accreditionImage}
              image={pageData.audience_image.gatsbyImageData as IGatsbyImageData}
              alt={pageData?.audience_image.alt || ''}
            />
          </div>
          <div>
            <DefaultButton
              target="_blank"
              to={pageData.page_button_link.text}
              variant="secondary"
              className={classes.heroButton}
            >
              {t('creatorsPage.buttonText')}
            </DefaultButton>
          </div>
        </section>
        <section className={classes.whereWeGo}>
          <div className={classes.sectionContent}>
            <H1 className={classes.whereWeGoHeader}>{t('creatorsPage.conclusion.title')}</H1>
            <div className={classes.stepsImageWrapper}>
              <GatsbyImage
                image={pageData.conclusion_desktop.gatsbyImageData as IGatsbyImageData}
                alt={pageData?.conclusion_desktop.alt || ''}
              />
            </div>
            <div className={classes.stepsMobileImageWrapper}>
              <GatsbyImage
                image={pageData.conclusion_mobile.gatsbyImageData as IGatsbyImageData}
                alt={pageData?.conclusion_mobile.alt || ''}
              />
            </div>
            <div>
              <DefaultButton
                target="_blank"
                to={pageData.page_button_link.text}
                variant="secondary"
                className={classes.heroButton}
              >
                {t('creatorsPage.buttonText')}
              </DefaultButton>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default withPrismicPreview(CreatorsPage);

export const query = graphql`
  query PrismicCreatorsPageQuery($language: String) {
    prismicCreatorLandingPageV2 {
      _previewable
      data {
        approach_image {
          gatsbyImageData
          alt
        }
        approach_steps {
          step_text {
            text
          }
        }
        approach_title {
          text
        }
        audience_description {
          text
        }
        audience_image {
          gatsbyImageData
          alt
        }
        audience_quotes {
          quote_text {
            text
          }
          quote_title {
            text
          }
        }
        audience_steps {
          step_title {
            text
          }
          step_description {
            text
          }
        }
        audience_title {
          text
        }
        conclusion_desktop {
          gatsbyImageData
          alt
        }
        conclusion_mobile {
          gatsbyImageData
          alt
        }
        conclusion_title {
          text
        }
        hero_image {
          url
          alt
        }
        hero_titles {
          title_item {
            text
          }
        }
        main_description {
          text
        }
        page_button_link {
          text
        }
        page_button_text {
          text
        }
        hacks_title {
          text
        }
        hacks_subtitle {
          text
        }
        your_hack_cover {
          gatsbyImageData
          alt
        }
        your_hack_title {
          text
        }
        your_hack_author {
          text
        }
        personal_growth_hacks {
          hack_id {
            text
          }
        }
        business_hacks {
          hack_id {
            text
          }
        }
        leadership_hacks {
          hack_id {
            text
          }
        }
        health_and_fitness_hacks {
          hack_id {
            text
          }
        }
        culture_and_society_hacks {
          hack_id {
            text
          }
        }
        career_and_success_hacks {
          hack_id {
            text
          }
        }
        parenting_and_relationships_hacks {
          hack_id {
            text
          }
        }
        creator_testimonial {
          creator_testimonial_avatar {
            gatsbyImageData
          }
          creator_testimonial_text {
            text
          }
          creator_testimonial_author {
            text
          }
          creator_testimonial_categories {
            text
          }
          uptime_link {
            url
          }
          twitter_link {
            url
          }
          linkedin_link {
            url
          }
          youtube_link {
            url
          }
          instagram_link {
            url
          }
          hack_link {
            url
          }
        }
        how_it_works_title {
          text
        }
        how_it_works_video {
          url
        }
        how_it_works_video_poster {
          url
        }
        how_it_works_steps {
          how_it_works_step_title {
            text
          }
          how_it_works_step_text {
            text
          }
        }
        meta_title {
          text
        }
        meta_image {
          url
        }
        meta_description {
          text
        }
        testimonials_awards_image {
          gatsbyImageData
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
