import React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import { Footnote, H1, BodyMedium, BodyBold } from '../components/Typography/Typography';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
// import JoinEmailList from '../components/JoinEmailList/JoinEmailList';
import FollowUs from '../components/FollowUs/FollowUs';
import BlogPostItem from '../components/BlogPostItem/BlogPostItem';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import htmlSerializer from '../utils/htmlSerializer';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: '#F8F8F8',
      width: '100%',
    },
    postContent: {
      padding: '32px 24px 0',
      marginBottom: '40px',
      [theme.breakpoints.up(600)]: {
        paddingTop: '126px',
        maxWidth: '1218px',
        margin: '0 auto 40px',
      },
    },
    date: {
      color: '#555B71',
      marginBottom: '8px',
      [theme.breakpoints.up(600)]: {
        maxWidth: '772px',
        margin: '0 auto 8px',
      },
    },
    title: {
      marginBottom: '16px',
      [theme.breakpoints.up(600)]: {
        margin: '0 auto 24px',
        maxWidth: '772px',
      },
    },
    subtitle: {
      marginBottom: '24px',
      [theme.breakpoints.up(600)]: {
        marginBottom: '40px',
        maxWidth: '772px',
        margin: '0 auto',
      },
    },
    image: {
      width: '100%',
      borderRadius: '8px',
      marginBottom: '32px',
      [theme.breakpoints.up(600)]: {
        marginBottom: '64px',
        borderRadius: '3px',
        filter: 'drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1))'
      },
    },
    article: {
      '& h1, h2, h3, h4, h5, h6': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.up(600)]: {
        maxWidth: '772px',
        margin: '0 auto',
      },
      '& p': {
        fontSize: '17px',
        lineHeight: '24px',
        color: '#4F4F4F',
        marginBottom: '24px',
        [theme.breakpoints.up(600)]: {
          lineHeight: '28px',
          marginBottom: '28px',
        },
      },
      '& h5': {
        fontSize: '21px',
        lineHeight: '30px',
        fontWeight: '500',
        marginBottom: '16px',
        color: theme.palette.primary.main,
      },
      '& a': {
        color: theme.palette.primary.main,
      },
      '& .quote': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'start',
        fontSize: '17px',
        lineHeight: '22px',
        color: theme.palette.primary.main,
        fontWeight: '500',
        margin: '40px 0',
        '& img': {
          width: '59px',
          marginRight: '11px',
        },
        [theme.breakpoints.up(600)]: {
          maxWidth: '549px',
          margin: '40px auto',
          fontSize: '21px',
          lineHeight: '30px',
          '& img': {
            width: '66px',
            marginRight: '16px',
          },
        },
      },
      '& .download-app': {
        display: 'none',
        backgroundColor: '#fff',
        padding: '48px 64px 0',
        gap: '56px',
        alignItems: 'center',
        margin: '64px 0',
        '& .phone-image': {
          display: 'block',
          borderRadius: '20.55px 20.55px 0 0',
          border: '3.34px solid #fff',
          borderBottom: '0',
          filter: 'drop-shadow(0px 6.12315px 35.6256px rgba(0, 0, 0, 0.14))',
        },
        '& .title': {
          fontSize: '24px',
          lineHeight: '30px',
          marginBottom: '16px',
          fontWeight: '700',
          color: theme.palette.primary.main,
        },
        '& .text': {
          fontSize: '15px',
          lineHeight: '20px',
          marginBottom: '32px',
          color: theme.palette.primary.main,
        },
        '& .buttons-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '32px',
          gap: '6px',
          '& img': {
            display: 'block',
            height: '47px',
          },
        },
        [theme.breakpoints.up(1200)]: {
          display: 'flex',
        }
      },
    },
    blogPosts: {
      marginBottom: '32px',
      [theme.breakpoints.up(697)]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '24px',
        maxWidth: '1218px',
        margin: '0 auto 72px',
      },
    },
    blogPostsTitle: {
      display: 'none',
      [theme.breakpoints.up(600)]: {
        display: 'block',
        margin: '0 0 32px',
      },
    },
    blogPost: {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up(697)]: {
        padding: 0,
        margin: 0,
        height: 'unset',
      },
    },
  };
});

const BlogPost = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const node = data?.prismicBlogPost;
  const relatedPosts = data?.relatedPosts?.edges || [];
  const fallbackPosts = data?.fallbackPosts?.edges || [];
  const renderedPosts = relatedPosts.length >= fallbackPosts.length ? relatedPosts : fallbackPosts;
  const formattedDate = moment(node?.data?.publish_date).format('MMMM DD, YYYY');
  const image = getImage(node?.data?.image);
  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar keepBackgroundColor />
      <main className={classes.root}>
        <div className={classes.postContent}>
          <Footnote className={classes.date}>
            {formattedDate}
          </Footnote>
          <H1 className={classes.title}>
            {node?.data?.title?.text}
          </H1>
          <BodyMedium className={classes.subtitle}>
            {node?.data?.subtitle?.text}
          </BodyMedium>
          <GatsbyImage className={classes.image} image={image as IGatsbyImageData} alt={node?.data?.image.alt || ''} />
          <div className={classes.article}>
            <PrismicRichText field={node?.data?.article.richText} components={htmlSerializer} />
          </div>
          <FollowUs />
          {renderedPosts.length > 0 && (
            <>
              <BodyBold className={classes.blogPostsTitle}>
                {t('blogPostPage.recommendedTitle')}
              </BodyBold>
              <div className={classes.blogPosts}>
                {renderedPosts.map(({ node: blogPost }) => {
                  return (
                    <BlogPostItem
                      className={classes.blogPost}
                      key={blogPost?.id}
                      id={blogPost?.id}
                      image={blogPost?.data?.image}
                      title={blogPost?.data?.title?.text}
                      date={blogPost?.data?.publish_date}
                      tags={blogPost?.tags}
                      url={`/blog/${blogPost?.slugs[0]}`}
                    />
                  )
                })}
              </div>
            </>
          )}
        </div>
        <DownloadComponent showPhones />
        {/* <JoinEmailList /> */}
      </main>
      <Footer />
    </>
  );
};

export default withPrismicPreview(BlogPost);

export const query = graphql`
  query BlogPostQuery($prismicId: ID, $tags: [String], $language: String) {
    prismicBlogPost(prismicId: {eq: $prismicId }) {
      _previewable
      id
      prismicId
      data {
        publish_date
        author {
          text
        }
        subtitle {
          text
        }
        title {
          text
        }
        image {
          url
          alt
          dimensions {
            width
            height
          }
          gatsbyImageData( width: 1170 )
        }
        article {
          richText
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
    relatedPosts: allPrismicBlogPost(filter: {tags: {in: $tags, nin: []}, prismicId: {ne: $prismicId}}, sort: {fields: data___publish_date, order: DESC }, limit: 3) {
      edges {
        node {
          id
          slugs
          tags
          data {
            title {
              text
            }
            image {
              url
              alt
              dimensions {
                width
                height
              }
              gatsbyImageData( width: 960 )
            }
            publish_date
          }
        }
      }
    }
    fallbackPosts: allPrismicBlogPost(filter: {prismicId: {ne: $prismicId}}, sort: {fields: data___publish_date, order: DESC }, limit: 3) {
      edges {
        node {
          id
          slugs
          tags
          data {
            title {
              text
            }
            image {
              url
              alt
              dimensions {
                width
                height
              }
              gatsbyImageData( width: 960 )
            }
            publish_date
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
