import React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from '@zachsz/gatsby-plugin-prismic-previews';

import LandingPage from './src/pages';
import PressPage from './src/pages/press_page';
import BlogHomePage from './src/pages/blog';
import BlogPost from './src/page-templates/BlogPostPage';
import TermsAndConditions from './src/pages/{PrismicTermsAndConditions.uid}';
import GiftingPage from './src/pages/gifting';
import DownloadPage from './src/pages/download';
import PromoCodePage from './src/pages/{PrismicPromoCodePage.url}';
import MarketingCampaign from './src/pages/promotion/{PrismicMarketingCampaign.url}';
import Creators from './src/pages/creators';

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver: (doc) => {
            switch (doc.type) {
              case 'landing_v3':
                return '/';
              case 'press_page':
                return '/press_page';
              case 'blog_home_page':
                return '/blog';
              case 'blog_post':
                return `/blog/${doc.slug}`;
              case 'terms_and_conditions':
                return `/${doc.uid}`;
              case 'gifting_page':
                return '/gifting';
              case 'download_page':
                return '/download';
              case 'promo_code_page':
                return `/${doc.slug}`;
              case 'marketing_campaign':
                return `/promotion/${doc.slug}`;
              case 'creators':
                return `/creators`;
            }
          },
          componentResolver: componentResolverFromMap({
            landing_v3: LandingPage,
            press_page: PressPage,
            blog_home_page: BlogHomePage,
            blog_post: BlogPost,
            terms_and_conditions: TermsAndConditions,
            gifting_page: GiftingPage,
            download_page: DownloadPage,
            promo_code_page: PromoCodePage,
            marketing_campaign: MarketingCampaign,
            creators: Creators,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  );
}
