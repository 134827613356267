import React from 'react';

function htmlSerializer(type, element, content, children) {
  if (type === 'paragraph') {
    if (element.text.startsWith('""') && element.text.endsWith('""')) {
      return (
        <div className="quote">
          <img src="/img/quote.png" alt="quotation marks" />
          {element.text.replace(/""/g, '')}
        </div>
      );
    }
    if (element.text === '{{download-app}}') {
      return (
        <div className="download-app">
          <div>
            <img className="phone-image" src="/img/blog-post/phone.png" alt="" />
          </div>
          <div>
            <p className="title">Turn your downtime into Uptime</p>
            <p className="text">We want to help you learn faster and make the changes you want to see. Try Uptime for free and see the benefits of learning in 5 minutes. </p>
            <div className="buttons-container">
              <a
                href="https://uptime.app.link/S5iL3GrK0cb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/blog-index/apple-button.svg" alt="" />
              </a>
              <a
                href="https://uptime.app.link/S5iL3GrK0cb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/blog-index/google-button.svg" alt="" />
              </a>
            </div>
            <img className="awards" src="/img/blog-index/awards.svg" alt="" />
          </div>
        </div>
      );
    }
  }
  return null;
}

export default htmlSerializer;
