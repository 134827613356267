import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { CheckCircle } from '@material-ui/icons';

import { BodyRegular, Footnote, H3 } from '../Typography/Typography';
import { DefaultButton } from '../Button/Button';
import { businessEnquiryFormFields, BusinessEnquiryFormSchema } from '../../utils/schemas';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    formContainer: {
      width: '100%',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '0px 16px 16px 16px',
      backgroundColor: '#fff',
      padding: '20px',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '386px',
      },
    },
    formInput: {
      display: 'flex',
      width: '100%',
      background: '#E9E9EC',
      borderRadius: '0px 8px 8px 8px',
      padding: '12px 16px',
      color: theme.palette.primary.main,
      fontSize: '15px',
      lineHeight: '20px',
      fontFamily: 'Rubik',
      border: 0,
      '&::placeholder': {
        color: theme.palette.primary.main,
        fontFamily: 'Rubik',
      },
    },
    demoButton: {
      backgroundColor: theme.palette.primary.main,
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: 'bold',
      padding: 12,
      width: 180,
      margin: '20px 0',
      borderRadius: '0px 8px 8px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorText: {
      color: 'red',
    },
  };
});

const FormInput = ({ id, labelText, type, helperText, ...rest }) => {
  const classes = useStyles();
  return (
    <Box component="div" marginBottom="12px">
      <input className={classes.formInput} type={type} placeholder={labelText} id={id} {...rest} />
      <Footnote className={classes.errorText}>{helperText}</Footnote>
    </Box>
  );
};
const encode = data => {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const handleFormSubmit = async ({ values }) => {
  try {
    const response = await fetch('/.netlify/functions/airtable', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    });
    if (!response.ok) {
      throw new Error(`error submitting form, code ${response.status}`);
    }
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const BusinessEnquiryForm = ({ id = 'business-form' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formSubmittedSuccess, setFormSubmittedSuccess] = useState(false);

  const formik = useFormik({
    validationSchema: BusinessEnquiryFormSchema,
    onSubmit: async values => {
      await handleFormSubmit({ values });
      setFormSubmittedSuccess(true);
    },
    initialValues: {
      'name': '',
      'company-name': '',
      'number-of-customers': '',
      'business-email': '',
      'contact-number': '',
    },
  });

  const fields = businessEnquiryFormFields.map((field) => {
    return {
      ...field,
      labelText: t(`businessEnquiryForm.fields.${field.id}`)
    }
  })

  return (
    <div id={id} className={classes.formContainer}>
      <form name="business-contact-form-v2" onSubmit={formik.handleSubmit} data-netlify="true">
        {formSubmittedSuccess ? (
          <Box display="flex" flexDirection="column">
            <BodyRegular color="green">{t('businessEnquiryForm.successMessage')}</BodyRegular>
          </Box>
        ) : (
          <>
            {fields.map(({ id, labelText, type }) => (
              <FormInput
                value={formik.values[id]}
                onChange={formik.handleChange}
                error={formik.touched[id] ? Boolean(formik.errors[id]) : undefined}
                helperText={formik.touched[id] && formik.errors[id]}
                key={id}
                id={id}
                name={id}
                labelText={labelText}
                type={type}
              />
            ))}
            <Box marginTop="24px" width="100%">
              <DefaultButton type="submit" variant="secondary" style={{ width: '100%', }}>
                {t('businessEnquiryForm.submitCTA')}
              </DefaultButton>
            </Box>
          </>
        )}
      </form>
    </div>
  );
};

export default BusinessEnquiryForm;
