import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Caption, H4 } from '../Typography/Typography';

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginBottom: '40px',
      [theme.breakpoints.up(600)]: {
        maxWidth: '632px',
        margin: '64px auto 64px',
      },
    },
    title: {
      marginBottom: '24px',
      color: theme.palette.primary.main,
    },
    container: {
      [theme.breakpoints.up(600)]: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #D4D4D4',
      },
    },
    followItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textDecoration: 'none',
      padding: '12px 0',
      borderTop: '1px solid #D4D4D4',
      boxSizing: 'border-box',
      '& img': {
        display: 'block',
        width: '32px',
        marginRight: '8px',
      },
      '&:last-child': {
        borderBottom: '1px solid #D4D4D4',
      },
      [theme.breakpoints.up(600)]: {
        border: 0,
        marginRight: '16px',
        '&:last-child': {
          border: 0,
          marginRight: 0,
        },
      },
    }
  };
})

const FollowUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <H4 className={classes.title}>Follow us &amp; share</H4>
      <div className={classes.container}>
        <a
          className={classes.followItem}
          href="https://www.facebook.com/Uptime-109826557071160/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src="/img/blog-index/facebook.svg" alt="facebook icon" />
          <Caption>Facebook</Caption>
        </a>
        <a
          className={classes.followItem}
          href="https://twitter.com/uptimeapp?lang=en"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src="/img/blog-index/twitter.svg" alt="twitter icon" />
          <Caption>Twitter</Caption>
        </a>
        <a
          className={classes.followItem}
          href="https://www.linkedin.com/company/uptime.app/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src="/img/blog-index/linkedin.svg" alt="linkedin icon" />
          <Caption>LinkedIn</Caption>
        </a>
      </div>
    </div>
  )
}

export default FollowUs;
