import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { QRCodeCanvas } from 'qrcode.react';
import AppBar from '../components/AppBar/AppBar';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import useBranchLink from '../hooks/useBranchLink';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import SEOHelmet from '../helmet/SEOHelmet';
import LegalFooter from '../components/Footer/LegalFooter';
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
      flex: '1 0 auto',
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 24px 0',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1054)]: {
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        maxWidth: '1006px',
        margin: '0 auto 72px',
        paddingTop: '112px',
        gap: '48px',
      },
    },
    textContainer: {
      maxWidth: '632px',
    },
    title: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      margin: '0 0 16px',
      [theme.breakpoints.up(700)]: {
        fontSize: '34px',
        lineHeight: '48px',
        fontWeight: '700',
      },
    },
    subTitle: {
      fontSize: '17px',
      lineHeight: '22px',
      marginBottom: '28px',
      color: '#4F4F4F',
      fontWeight: '500',
      [theme.breakpoints.up(700)]: {
        fontSize: '19px',
        lineHeight: '28px',
      },
    },
    subTitleLink: {
      color: theme.palette.secondary.main,
      '&:visited': {
        color: theme.palette.secondary.main,
      }
    },
    text: {
      fontSize: '17px',
      lineHeight: '22px',
      marginBottom: '48px',
      whiteSpace: 'pre-line',
      [theme.breakpoints.up(700)]: {
        fontSize: '19px',
        lineHeight: '28px',
      },
    },
    qrContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    qrContainerInner: {
      display: 'flex',
      justifyContent: 'center',
      padding: '44px',
      backgroundColor: '#fff',
      borderRadius: '16px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      marginBottom: '16px',
    },
    qrText: {
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: '500',
      color: '#4F4F4F',
      textAlign: 'center',
      margin: '0 0 12px',
    },
    qrButton: {
      borderRadius: '0 8px 8px',
      padding: '8px 12px',
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0',
      marginBottom: '24px',
    },
    awardsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '24px',
      alignItems: 'center',
      padding: '0 24px 0',
      marginBottom: '48px',
      [theme.breakpoints.up(1054)]: {
        padding: 0,
        maxWidth: '1006px',
        margin: '0 auto 120px',
        gap: '76px',
      },
      '& img': {
        display: 'block',
      },
    },
    awardTextBottom: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '500',
      color: '#767676',
    },
    awardTextRight: {
      display: 'block',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '500',
      color: '#767676',
      whiteSpace: 'pre-line',
      flexShrink: '0',
    },
    instructionsContainer: {
      padding: '0 24px 0',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1054)]: {
        padding: 0,
        maxWidth: '1006px',
        margin: '0 auto 120px',
      },
    },
    instructionsTitle: {
      fontSize: '25px',
      lineHeight: '34px',
      fontWeight: '500',
      margin: '0 0 16px',
    },
    instructionsList: {
      margin: '0 0 16px',
      fontSize: '19px',
      lineHeight: '28px',
      color: '#4F4F4F',
      maxWidth: '632px',
      paddingInlineStart: '1em',
    },
    instructionsFootnote: {
      fontSize: '19px',
      lineHeight: '28px',
      color: '#4F4F4F',
    },
    instructionsFootnoteLink: {
      color: '#4F4F4F',
    },
    didntWorkTitle: {
      fontSize: '19px',
      lineHeight: '28px',
      fontWeight: '700',
      color: '#4F4F4F',
      margin: '0 0 16px',
    },
    didntWorkText: {
      fontSize: '19px',
      lineHeight: '28px',
      fontWeight: '400',
      color: '#4F4F4F',
      maxWidth: '632px',
      margin: '0 0 16px',
    },
    iphoneUsersTitle: {
      fontSize: '19px',
      lineHeight: '28px',
      fontWeight: '700',
      color: '#4F4F4F',
      margin: '0 0 16px',
    },
    iphoneUsersText: {
      fontSize: '19px',
      lineHeight: '28px',
      fontWeight: '400',
      color: '#4F4F4F',
      maxWidth: '632px',
      margin: '0 0 16px',
    }
  };
});

const DefaultContent = ({ classes, title, subtitle, text }) => {
  return (
    <div className={classes.textContainer}>
      <h1 className={classes.title}>{title}</h1>
      <p className={classes.subTitle}>{subtitle}</p>
      <p className={classes.text}>{text}</p>
    </div>
  );
};

const BranchDeeplinkPage = ({ location }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  let code = queryParams.get('access_code');
  if (!code) {
    code = queryParams.get('company_code');
  }
  const url = `${queryParams.get('url') || ''}${code ? `?access_code=${code}` : ''}`
  const { branchLink, linkData } = useBranchLink(null, url);

  const isB2bFlow = useMemo(() => {
    return !!url;
  }, [url]);

  const isOldB2bFlow = useMemo(() => {
    return (linkData?.organisationId && linkData?.organisationName);
  }, [linkData]);

  const parsedInstructions = useMemo(() => {
    const instructions = isB2bFlow ? t('downloadPage.instructions.list') : t('downloadPage.instructions.generic.list');
    return instructions.replace(/\<li\>/g, '').replace(/\<\/li\>/g, '|').split('|').filter(Boolean);
  }, [isB2bFlow, t]);

  const webAppUrl = useMemo(() => {
    const baseUrl = process.env.GATSBY_STAGE === 'prod' ? 'https://web.uptime.app/' : 'https://staging.webapp.uptimedev.io/';
    if (isB2bFlow) {
      return `${baseUrl}?access_code=${code}`;
    } else {
      return `${baseUrl}`;
    }
  }, [isB2bFlow, code]);

  return (
    <>
      <SEOHelmet
        location={location}
        title={t('downloadPage.seo.title')}
        description={t('downloadPage.seo.description')}
        overrideTitle
      />
      <AppBar overrideButtons={<LanguageSwitcher hideDivider />} maxWidth="1054px" removeLogoLink disableBurgerMenu keepBackgroundColor />
      <main className={classes.root}>
        <div className={classes.inner}>
          {isOldB2bFlow ? (
            <DefaultContent
              classes={classes}
              title={t('downloadPage.b2bContent.title', { orgName: linkData?.organisationName })}
              subtitle={t('downloadPage.b2bContent.subTitle')}
              text={t('downloadPage.b2bContent.body')}
            />
          ) : isB2bFlow ? (
            <div className={classes.textContainer}>
              <h1 className={classes.title}>
                {t('downloadPage.title')}
              </h1>
              <p className={classes.subTitle}>
                <Trans
                  i18nKey={'optumPage.subTitle'}
                  components={{
                    l1: (
                      <a className={classes.subTitleLink} href={webAppUrl} />
                    ),
                  }}
                />
              </p>
              <p className={classes.text}>{t('downloadPage.text')}</p>
            </div>
          ) : (
            <DefaultContent
              classes={classes}
              title={t('downloadPage.title')}
              subtitle={t('downloadPage.subTitle')}
              text={t('downloadPage.text')}
            />
          )}
          <div>
            <div className={classes.qrContainer}>
              {isB2bFlow && <p className={classes.qrText}>{t('optumPage.qrCode.scanIt')}</p>}
              <div className={classes.qrContainerInner}>
                {branchLink ? (
                  <QRCodeCanvas value={branchLink} fgColor="#1F2744" size={200} level="H" />
                ) : (
                  <CircularProgress size="200px" />
                )}
              </div>
              {isB2bFlow && (
                <>
                  <p className={classes.qrText}>{t('optumPage.qrCode.or')}</p>
                  <Button
                    className={classes.qrButton}
                    variant="contained"
                    color="secondary"
                    href={webAppUrl}
                  >
                    {t('optumPage.qrCode.continueToWebApp')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classes.awardsContainer}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <img src="/img/optum/app-store-rating.svg" />
            <span className={classes.awardTextBottom}>{t('downloadPage.awards.appleRating')}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', }}>
            <img src="/img/optum/google.svg" />
            <span className={classes.awardTextRight}>{t('downloadPage.awards.googleAward')}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', }}>
            <img src="/img/optum/apple-award.svg" />
            <span className={classes.awardTextBottom}>{t('downloadPage.awards.appleAward')}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', }}>
            <img src="/img/optum/fast-company.svg" />
            <span className={classes.awardTextRight}>{t('downloadPage.awards.fastCompany')}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <img src="/img/optum/google-store-rating.svg" />
            <span className={classes.awardTextBottom}>{t('downloadPage.awards.googleRating')}</span>
          </div>
        </div>
        <div className={classes.instructionsContainer}>
          <h2 className={classes.instructionsTitle}>
            {t('optumPage.instructions.title')}
          </h2>
          <ol className={classes.instructionsList}>
            {parsedInstructions.map((line) => (
              <li>{line}</li>
            ))}
          </ol>
          {(isB2bFlow || isOldB2bFlow) && (
            <div>
              <p className={classes.didntWorkTitle}>
                {t('optumPage.didntWork.title')}
              </p>
              <p className={classes.didntWorkText}>
                {t('optumPage.didntWork.text')}
              </p>
            </div>
          )}
          <p className={classes.iphoneUsersTitle}>
            {t('optumPage.iphoneUsers.title')}
          </p>
          <p className={classes.iphoneUsersText}>
            {(isB2bFlow || isOldB2bFlow) ? t('downloadPage.iPhoneUsers.b2b') : t('downloadPage.iPhoneUsers.generic')}
          </p>
          <p className={classes.instructionsFootnote}>
            <Trans
              i18nKey={'downloadPage.instructions.footnote'}
              components={{
                l1: (
                  <a className={classes.instructionsFootnoteLink} href="mailto:support@uptime.app" />
                ),
              }}
            />
          </p>
        </div>
      </main >
      <LegalFooter />
    </>
  );
};

export default withPrismicPreview(BranchDeeplinkPage);

export const query = graphql`
  query DownloadPageQuery($language: String) {
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
