import React from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TopContainer from '../components/TopContainer/TopContainer';
import BottomSection from '../components/BottomSection/BottomSection';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      flex: '1 0 auto',
    },
    subHeading: {
      display: 'inline-block',
      marginBottom: '10px',
    },
    heroTextContainer: {
      [theme.breakpoints.up(600)]: {
        width: '53%',
      },
      [theme.breakpoints.up(1200)]: {
        width: '50%',
      },
    },
    heroImage: {
      display: 'block',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(600)]: {
        width: '47%',
      },
      [theme.breakpoints.up(682)]: {
        width: '50%',
        marginBottom: 0,
      },
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& a': {
        [theme.breakpoints.up(600)]: {
          margin: '0 50px 20px 0',
        },
        margin: '0 0 20px 0',
      },
    },
    button: {
      color: theme.palette.text.primary,
      fontSize: 19,
      fontWeight: 'bold',
      backgroundColor: '#fff',
      boxShadow: '1px 0px 18px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '0 10px 10px',
    },
  };
});

const GiftingPage = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    prismicGiftingPage: { data: pageData },
  } = data;
  return (
    <>
      <SEOHelmet
        title={t('giftingPage.seo.title')}
        description={t('giftingPage.seo.description')}
        location={location}
        image={{
          url: '/img/gift-page/giftcard-hero.png',
          alt: t('giftingPage.seo.image.alt'),
        }}
        locale={pageContext?.language}
      />
      <AppBar />
      <main className={classes.root}>
        <TopContainer removeMarginBottom isReverse limitTextWidth>
          <img className={classes.heroImage} src="/img/gift-page/giftcard-hero.png" alt={t('giftingPage.seo.image.alt')} />
          <div className={classes.heroTextContainer}>
            <Typography className={classes.h1} variant="h1">
              {pageData?.title?.text}
            </Typography>
            <Typography className={classes.h2} variant="subtitle1">
              <span className={classes.subHeading}>{pageData?.sub_heading?.text}</span> <br />
              {pageData?.description?.text}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant="contained"
                target="_blank"
                href={pageData?.button_one_link?.url}
              >
                {pageData?.button_one?.text}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                target="_blank"
                href={pageData?.button_two_link?.url}
              >
                {pageData?.button_two?.text}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                target="_blank"
                href={pageData?.button_three_link?.url}
              >
                {pageData?.button_three?.text}
              </Button>
            </div>
          </div>
        </TopContainer>
        <BottomSection reducedMargin bgColor="#03091D" />
      </main>
      <Footer />
    </>
  );
};

export default withPrismicPreview(GiftingPage);

export const query = graphql`
  query PrismicGiftingPageQuery($language: String) {
    prismicGiftingPage {
      _previewable
      data {
        title {
          text
        }
        sub_heading {
          text
        }
        description {
          text
        }
        button_one {
          text
        }
        button_one_link {
          url
        }
        button_three {
          text
        }
        button_three_link {
          url
        }
        button_two {
          text
        }
        button_two_link {
          url
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
