import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      background: '#fff',
      boxShadow: '1px 1px 18px 0 rgba(0, 0, 0, 0.08)',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      overflow: 'hidden',
      maxWidth: 324,
      [theme.breakpoints.up(600)]: {
        height: '100%',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: 132,
      overflow: 'hidden',
      padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
      boxSizing: 'border-box',
    },
    imageInner: {
      display: 'block',
      width: '100%',
      height: 'fit-content',
      maxHeight: 100,
      objectFit: 'contain',
      margin: 'auto 0',
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 132px)',
      padding: theme.spacing(2),
    },
    date: {
      fontSize: 14,
    },
    title: {
      fontSize: 20,
      marginBottom: theme.spacing(2),
    },
    text: {
      fontSize: 14,
      marginBottom: theme.spacing(4),
    },
    link: {
      fontSize: 14,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
  };
});

const PressPageArticle = ({ date, title, text, url, image }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className={classes.root}>
      <div className={classes.imageContainer} style={{ backgroundColor: image?.url ? '#fff' : '#c4c4c4' }}>
        {image?.url && <img className={classes.imageInner} src={image.url} alt={image.alt} />}
      </div>
      <div className={classes.inner}>
        <div>
          <Typography className={classes.date}>{date}</Typography>
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {text}
          </Typography>
        </div>
        <div>
          <a className={classes.link} href={url} target="_blank" rel="noopener noreferrer">
            {t('pressPageArticle.readNowCTA')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PressPageArticle;
