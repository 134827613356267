import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChevronRightIcon = ({ ...props }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 18.6836C8 18.5099 8.06795 18.3471 8.19065 18.2244L14.2884 12.1366C14.3622 12.0644 14.3617 11.9357 14.2882 11.8629L8.19137 5.77597C8.06891 5.65351 8.0012 5.49079 8.0012 5.31704L8.0012 5.3168C8.0012 5.14305 8.06891 4.97961 8.19161 4.85667C8.31527 4.73397 8.47903 4.66663 8.6524 4.66663C8.82624 4.66663 8.99001 4.73397 9.11319 4.85715L15.8091 11.5408C15.9304 11.6613 16 11.8289 16 12.0002C16 12.1739 15.932 12.3369 15.8091 12.4603L9.11294 19.1428C8.98976 19.266 8.82576 19.3333 8.65144 19.3333C8.47735 19.3333 8.31359 19.266 8.19065 19.1428C8.06795 19.0201 8 18.8571 8 18.6836" fill="#1F2744" />
    <path d="M8 18.6836C8 18.5099 8.06795 18.3471 8.19065 18.2244L14.2884 12.1366C14.3622 12.0644 14.3617 11.9357 14.2882 11.8629L8.19137 5.77597C8.06891 5.65351 8.0012 5.49079 8.0012 5.31704L8.0012 5.3168C8.0012 5.14305 8.06891 4.97961 8.19161 4.85667C8.31527 4.73397 8.47903 4.66663 8.6524 4.66663C8.82624 4.66663 8.99001 4.73397 9.11319 4.85715L15.8091 11.5408C15.9304 11.6613 16 11.8289 16 12.0002C16 12.1739 15.932 12.3369 15.8091 12.4603L9.11294 19.1428C8.98976 19.266 8.82576 19.3333 8.65144 19.3333C8.47735 19.3333 8.31359 19.266 8.19065 19.1428C8.06795 19.0201 8 18.8571 8 18.6836" stroke="#1F2744" />
  </SvgIcon>
);

export default ChevronRightIcon;
