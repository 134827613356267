import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TopicTag from '../TopicTag/TopicTag';
import { Footnote } from '../Typography/Typography';
import { filterBlogTags } from '../../utils/utils';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: '0 8px 8px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      textDecoration: 'none',
      [theme.breakpoints.up(697)]: {
        height: '100%',
        flex: '0 0 calc(50% - 24px)',
      },
      [theme.breakpoints.up(1025)]: {
        flex: '0 0 calc(33.333333% - 16px)',
      },
    },
    image: {
      borderRadius: '0 8px 8px',
      height: '177px',
      transform: 'translateZ(0)',
    },
    content: {
      display: 'flex',
      minHeight: '170px',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '16px 16px 12px 16px',
      height: '100%',
      [theme.breakpoints.up(697)]: {
        height: 'unset',
        flexGrow: 1,
      }
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: 8,
    },
    h3: {
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      marginBottom: 0,
      width: '100%',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
    footnote: {
      marginBottom: 0,
    }
  }
})

const BlogPostItem = ({
  className,
  id,
  image,
  date,
  title,
  url,
  tags = [],
}) => {
  const classes = useStyles();
  const filteredTags = filterBlogTags(tags);
  const imageToRender = getImage(image);
  return (
    <a className={classnames(classes.root, className)} href={url}>
      <GatsbyImage className={classes.image} image={imageToRender} />
      <div className={classes.content}>
        <div>
          <div className={classes.tagsContainer}>
            {filteredTags.map(tag => {
              return (
                <TopicTag key={tag} topic={tag} />
              )
            })}
          </div>
          <div className={classes.h3}>
            {title}
          </div>
        </div>
        <Footnote className={classes.footnote}>
          {date}
        </Footnote>
      </div>
    </a>
  );
}

export default BlogPostItem;
