import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    h1: {
      letterSpacing: 'inherit',
      fontSize: '24px',
      lineHeight: '30px',
      fontStyle: 'normal',
      marginBottom: '16px',
      marginTop: 0,
      fontWeight: 700,
      color: theme.palette.text.primary,
      [theme.breakpoints.up(600)]: {
        fontSize: '34px',
        lineHeight: '40px',
        marginBottom: '24px',
      },
    },
    h2: {
      letterSpacing: 'inherit',
      fontSize: '21px',
      lineHeight: '30px',
      fontStyle: 'normal',
      marginBottom: '16px',
      fontWeight: 500,
      marginTop: 0,
      color: theme.palette.text.primary,
      [theme.breakpoints.up(600)]: {
        fontSize: '24px',
        lineHeight: '30px',
        fontWeight: 700,
        marginBottom: '24px',
      },
    },
    h3: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '22px',
      fontStyle: 'normal',
      marginBottom: '16px',
      marginTop: 0,
      fontWeight: 500,
      color: theme.palette.text.primary,
      [theme.breakpoints.up(600)]: {
        fontSize: '21px',
        lineHeight: '30px',
        fontWeight: 500,
        marginBottom: '24px',
      },
    },
    h4: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '22px',
      fontStyle: 'normal',
      marginBottom: '0',
      marginTop: 0,
      fontWeight: 500,
    },
    bodyBold: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
    bodyMedium: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '22px',
      fontStyle: 'normal',
      fontWeight: 500,
      marginTop: 0,
      color: theme.palette.text.primary,
    },
    bodyRegular: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginTop: 0,
      color: theme.palette.text.primary,
    },
    bodySmallRegular: {
      letterSpacing: 'inherit',
      fontSize: '17px',
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginTop: 0,
      color: theme.palette.text.primary,
    },
    footnote: {
      letterSpacing: 'inherit',
      fontSize: '12px',
      lineHeight: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginTop: 0,
      color: theme.palette.text.primary,
    },
    label: {
      letterSpacing: 'inherit',
      fontSize: '10px',
      lineHeight: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginTop: 0,
      color: theme.palette.text.primary,
    },
    caption: {
      letterSpacing: 'inherit',
      fontSize: '13px',
      lineHeight: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginTop: 0,
      marginBottom: 0,
      color: theme.palette.text.primary,
    },
  };
});

export const H1 = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <h1 style={{ color }} {...rest} className={classnames(classes.h1, rest.className)}>
      {children}
    </h1>
  );
};

export const H2 = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <h2 style={{ color }} {...rest} className={classnames(classes.h2, rest.className)}>
      {children}
    </h2>
  );
};

export const H3 = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <h3 style={{ color }} {...rest} className={classnames(classes.h3, rest.className)}>
      {children}
    </h3>
  );
};

export const H4 = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <h4 style={{ color }} {...rest} className={classnames(classes.h4, rest.className)}>
      {children}
    </h4>
  );
};


export const BodyBold = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p {...rest} className={classnames(classes.bodyBold, rest.className)} style={{ color }}>
      {children}
    </p>
  );
};

export const BodyMedium = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.bodyMedium, rest.className)}>
      {children}
    </p>
  );
};

export const BodyRegular = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.bodyRegular, rest.className)}>
      {children}
    </p>
  );
};

export const BodySmallRegular = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.bodySmallRegular, rest.className)}>
      {children}
    </p>
  );
};

export const Footnote = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.footnote, rest.className)}>
      {children}
    </p>
  );
};

export const Label = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.label, rest.className)}>
      {children}
    </p>
  );
};

export const Caption = ({ children, color, ...rest }: any) => {
  const classes = useStyles();
  return (
    <p style={{ color }} {...rest} className={classnames(classes.caption, rest.className)}>
      {children}
    </p>
  );
};
