import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { tan6 } from '../../utils/utils';

const useStyles = makeStyles(theme => {
  return {
    bottomSectionClipRect: {
      overflow: 'hidden',
    },
    bottomSection: {
      backgroundColor: theme.palette.primary.main,
      transform: 'skewY(-6.733deg)',
      margin: `calc((100vw * ${tan6} * -1 ) / 2) 0`,
      marginBottom: `calc((100vw * ${tan6}) / 2)`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 0 0',
    },
    bottomSectionContent: {
      padding: '20px 25px',
      transform: 'skewY(6.733deg)',
      [theme.breakpoints.up(600)]: {
        padding: '20px 50px',
      },
      [theme.breakpoints.up(1200)]: {
        padding: '20px 70px',
      },
    },
    bottomSectionContentWithChildren: {
      padding: '20px 25px',
      transform: 'skewY(6.733deg)',
      textAlign: 'center',
      '& h2': {
        color: '#fff',
        fontSize: 24,
        lineHeight: '50px',
        letterSpacing: -0.5,
      },
      '& h6': {
        color: '#fff',
        fontSize: 18,
        lineHeight: '21.33px',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up(600)]: {
        padding: '20px 50px',
        '& h2': {
          fontSize: 36,
          marginBottom: theme.spacing(),
        },
        '& h6': {
          marginBottom: theme.spacing(6),
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: 0,
        maxWidth: 1006,
        margin: '0 auto',
        '& h2': {
          fontSize: 32,
          lineHeight: '50px',
          marginBottom: theme.spacing(),
        },
        '& h6': {
          fontSize: 16,
          lineHeight: '18.96px',
          marginBottom: theme.spacing(6),
        },
      },
    },
  };
});

const BottomSection = ({ reducedMargin, children, bgColor }) => {
  const classes = useStyles({ bgColor });
  return (
    <div
      className={classes.bottomSectionClipRect}
      style={{ marginTop: reducedMargin ? `calc((100vw * ${tan6}) / 2 + 48px)` : '' }}
    >
      <div className={classes.bottomSection} style={bgColor ? { backgroundColor: bgColor } : undefined}>
        <div className={children ? classes.bottomSectionContentWithChildren : classes.bottomSectionContent}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
