import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { tan6 } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.secondary.main,
    transform: 'skewY(-6.733deg)',
    marginTop: `calc((100vw * ${tan6}))`,
    marginBottom: `calc((100vw * ${tan6} * -1) / 2)`,
    padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
    borderRadius: '0 0 25px 50px',
    overflow: 'hidden',
    [theme.breakpoints.up(600)]: {
      marginBottom: `calc((600px * ${tan6} * -1) / 2)`,
      paddingBottom: `calc((600px * ${tan6} * -1) / 2)`,
    },
    [theme.breakpoints.up(1170)]: {
      marginBottom: `calc((1170px * ${tan6} * -1) / 2)`,
      paddingBottom: `calc((1170px * ${tan6} * -1) / 2)`,
    },
  },
  heroContainerContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '76px 24px 0',
    transform: 'skewY(6.733deg)',
    maxWidth: 600,
    margin: '0 auto',
    marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
    '& h1': {
      color: '#fff',
      fontSize: (props: any) => (props.variant === 'hack-container' ? 28 : 34),
      lineHeight: (props: any) => (props.variant === 'hack-container' ? '33.18px' : '40px'),
      letterSpacing: (props: any) => (props.variant === 'hack-container' ? '-0.01562em' : 0),
      marginBottom: '24px',
    },
    '& h6': {
      color: '#fff',
      fontSize: 16,
      lineHeight: '23px',
      marginBottom: 32,
      fontWeight: '400',
    },
    [theme.breakpoints.up(1200)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '100px 24px 57px',
      maxWidth: 1218,
      '& h1': {
        fontSize: (props: any) => (props.variant === 'hack-container' ? 28 : 34),
        lineHeight: (props: any) => (props.variant === 'hack-container' ? '34px' : '40px'),
        marginBottom: (props: any) => (props.variant === 'hack-container' ? theme.spacing(2) : 32),
      },
      '& h6': {
        fontSize: 16,
        lineHeight: '23px',
        fontWeight: '400',
        marginBottom: 50,
      },
    },
  },
}));

const HeroContainer: FC<any> = ({ children, classes, ...props }) => {
  const { heroContainer, heroContainerContent } = useStyles(props);
  return (
    <div className={classnames(heroContainer, classes?.heroContainer)}>
      <div className={classnames(heroContainerContent, classes?.heroContainerContent)}>{children}</div>
    </div>
  );
};

export default HeroContainer;
