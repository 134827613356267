import React from 'react';

const GoogleIcon = ({ color = '#767676' }) => {
  return (
    <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.04033 0L22.8337 20.6433L28.5346 14.9424L3.33053 0.390063C2.91047 0.146274 2.46039 0.011252 2.04033 0ZM0.300048 0.960154C0.112519 1.29396 0 1.68777 0 2.13034V42.2468C0 42.5693 0.0675113 42.8581 0.180029 43.1169L21.4534 21.9935L0.300048 0.960154ZM30.2749 15.9326L24.1839 21.9935L30.2749 28.0245L37.7161 23.7638C38.7737 23.1525 38.9237 22.3761 38.9162 21.9635C38.905 21.2809 38.4737 20.6433 37.7461 20.2533C37.1122 19.9119 32.4052 17.1703 30.2749 15.9326ZM22.8337 23.3437L1.8603 44.1371C2.2091 44.1183 2.58792 44.0433 2.94047 43.837C3.76185 43.3607 20.3733 33.7554 20.3733 33.7554L28.5646 29.0447L22.8337 23.3437Z" fill={color} />
    </svg>

  )
}

export default GoogleIcon;
