import React, { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDraggable } from 'react-use-draggable-scroll';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import HackLabel from '../HackLabel/HackLabel';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import PodcastCover from '../PodcastCover/PodcastCover';
import { getCreatorHacks } from '../../utils/api';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      marginBottom: '32px',
    },
    categoriesOuter: {
      width: '100%',
      position: 'relative',
    },
    categories: {
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
      width: '100%',
      overflowX: 'auto',
      marginBottom: '32px',
      zIndex: '0',
      '-ms-overflow-style': 'none',  /* IE and Edge */
      scrollbarWidth: 'none',  /* Firefox */
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    categoriesUnderline: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '2px',
      borderRadius: '9px',
      backgroundColor: '#E9E9EC',
      zIndex: '1',
    },
    category: {
      position: 'relative',
      fontSize: '15px',
      lineHeight: '20px',
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      paddingBottom: '6px',
      cursor: 'pointer',
      [theme.breakpoints.up(1200)]: {
        fontSize: '17px',
        lineHeight: '24px',
      },
    },
    selectedHighlight: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '4px',
      borderRadius: '9px',
      backgroundColor: theme.palette.primary.main,
      zIndex: '2',
    },
    hacksContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      width: '100%',
    },
    hackItem: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      alignItems: 'center',
      backgroundColor: '#FFF',
      borderRadius: '0 8px 8px 8px',
      boxShadow: '0 5px 20px rgba(0, 0, 0, 0.05)',
      padding: '16px',
      width: '100%',
      textDecoration: 'none',
    },
    hackCover: {
      display: 'block',
      width: '81px',
      height: '119px',
      borderRadius: '0 4px 4px 4px',
      backgroundColor: '#E9E9EC',
      objectFit: 'cover',
      overflow: 'hidden',
      flexShrink: '0',
    },
    detailsContainer: {
      overflow: 'hidden',
      [theme.breakpoints.up(1200)]: {
        width: '316px',
      }
    },
    hackTitle: {
      fontSize: '21px',
      lineHeight: '28px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      margin: 0,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    hackAuthor: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      margin: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    hackDescription: {
      display: 'none',
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '8px',
      }
    },
    descriptionText: {
      fontSize: '13px',
      lineHeight: '18px',
      color: '#4F4F4F',
      fontWeight: '400',
    },
    placeholderText: {
      display: 'block',
      backgroundColor: '#E9E9EC',
      borderRadius: '17px',
      height: '12px',
      width: '100%',
    },
    caveatContainer: {
      position: 'absolute',
      display: 'none',
      top: '90px',
      left: '-150px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '40px',
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
      }
    },
    arrow: {
      display: 'block',
      width: '46px',
      transform: 'rotate(94deg)',
      marginLeft: '50px',
      alignSelf: 'center',
    },
    caveatText: {
      display: 'block',
      fontFamily: 'Caveat, sans-serif',
      fontSize: '24px',
      lineHeight: '24px',
      textAlign: 'center',
      fontWeight: '700',
      color: theme.palette.primary.main,
      margin: 0,
      width: '150px',
    },
    arrowButton: {
      display: 'none',
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        backgroundColor: '#FFF',
        marginLeft: '32px',
        marginRight: '8px',
        border: 0,
        padding: 0,
        borderRadius: '100%',
        boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
      }
    }
  }
})

const categories = [
  'Personal Growth',
  'Business',
  'Leadership & Entrepreneurship',
  'Health & Fitness',
  'Culture & Society',
  'Career & Success',
  'Parenting & Relationships',
];

export type CreatorsHackListProps = {
  placeholder: {
    title: string;
    author: string;
    cover: IGatsbyImageData;
  };
  hacks?: {
    'Personal Growth'?: string[];
    'Business'?: string[];
    'Leadership & Entrepreneurship'?: string[];
    'Health & Fitness'?: string[];
    'Culture & Society'?: string[];
    'Career & Success'?: string[];
    'Parenting & Relationships'?: string[];
  }
}

const CreatorsHackList: FC<CreatorsHackListProps> = ({
  placeholder,
  hacks,
}) => {
  const classes = useStyles();
  const { language } = useI18next();
  const { t } = useTranslation();
  const ref = useRef();
  const { events } = useDraggable(ref);
  const [hackList, setHackList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Personal Growth');

  useEffect(() => {
    const hackIds = hacks?.[selectedCategory] || [];
    getCreatorHacks({ topic: selectedCategory, setHacks: setHackList, hackIds, language: language.split('-')[0] });
  }, [selectedCategory, setHackList]);

  return (
    <div className={classes.root}>
      <div className={classes.categoriesOuter}>
        <div className={classes.categories} ref={ref} {...events}>
          {categories.map((category) => {
            return (
              <a key={category} className={classes.category} onClick={() => {
                setSelectedCategory(category);
              }}>
                {t(`creatorsHackList.categories.${category}`)}
                {selectedCategory === category && (
                  <div className={classes.selectedHighlight} />
                )}
              </a>
            )
          })}
        </div>
        <div className={classes.categoriesUnderline} />
      </div>
      <div className={classes.hacksContainer}>
        {hackList.map((hack) => {
          const type = hack.type;
          const contributors = hack.contributors.map(({ name }) => name).join(', ');
          return (
            <a key={hack.id} href={hack.branchLink} className={classes.hackItem}>
              {hack.type === 'podcast' ? (
                <div className={classes.hackCover}>
                  <PodcastCover title={hack.title} imageUrl={hack.coverUrl} />
                </div>
              ) : (
                <img className={classes.hackCover} src={hack.coverUrl} />
              )}
              <div className={classes.detailsContainer}>
                <HackLabel hackType={type} />
                <span className={classes.hackTitle}>{hack.title}</span>
                <span className={classes.hackAuthor}>{t('creatorsHackList.by')} {contributors}</span>
              </div>
              <div className={classes.hackDescription}>
                <p className={classes.descriptionText}>
                  {hack.description}
                </p>
              </div>
              <button className={classes.arrowButton}>
                <ChevronRightIcon />
              </button>
            </a>
          )
        })}
        <div className={classes.hackItem}>
          <GatsbyImage
            className={classes.hackCover}
            image={placeholder.cover as IGatsbyImageData}
            alt=""
          />
          <div className={classes.detailsContainer}>
            <HackLabel hackType="original" />
            <span className={classes.hackTitle}>{placeholder.title}</span>
            <span className={classes.hackAuthor}>{placeholder.author}</span>
          </div>
          <div className={classes.hackDescription}>
            <span className={classes.placeholderText} />
            <span className={classes.placeholderText} style={{ width: '90%' }} />
            <span className={classes.placeholderText} style={{ width: '95%' }} />
          </div>
          <button className={classes.arrowButton}>
            <ChevronRightIcon />
          </button>
          <div className={classes.caveatContainer}>
            <img className={classes.arrow} src="/img/creators/arrow.svg" alt="" />
            <span className={classes.caveatText}>{t('creatorsHackList.caveatText')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreatorsHackList;
