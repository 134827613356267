import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChevronDownIcon = ({ fill = '#1F2744', ...props }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6837 8C18.51 8 18.3472 8.06795 18.2245 8.19065L12.1367 14.2884C12.0645 14.3622 11.9359 14.3617 11.863 14.2882L5.77609 8.19137C5.65363 8.06891 5.49091 8.0012 5.31716 8.0012H5.31692C5.14318 8.0012 4.97973 8.06891 4.85679 8.19161C4.73409 8.31527 4.66675 8.47903 4.66675 8.6524C4.66675 8.82624 4.73409 8.99001 4.85727 9.11319L11.5409 15.8091C11.6615 15.9304 11.829 16 12.0003 16C12.1741 16 12.337 15.932 12.4605 15.8091L19.1429 9.11295C19.2661 8.98977 19.3334 8.82576 19.3334 8.65144C19.3334 8.47735 19.2661 8.31359 19.1429 8.19065C19.0202 8.06795 18.8572 8 18.6837 8Z"
      fill={fill}
    />
    <path
      d="M18.6837 8C18.51 8 18.3472 8.06795 18.2245 8.19065L12.1367 14.2884C12.0645 14.3622 11.9359 14.3617 11.863 14.2882L5.77609 8.19137C5.65363 8.06891 5.49091 8.0012 5.31716 8.0012H5.31692C5.14318 8.0012 4.97973 8.06891 4.85679 8.19161C4.73409 8.31527 4.66675 8.47903 4.66675 8.6524C4.66675 8.82624 4.73409 8.99001 4.85727 9.11319L11.5409 15.8091C11.6615 15.9304 11.829 16 12.0003 16C12.1741 16 12.337 15.932 12.4605 15.8091L19.1429 9.11295C19.2661 8.98977 19.3334 8.82576 19.3334 8.65144C19.3334 8.47735 19.2661 8.31359 19.1429 8.19065C19.0202 8.06795 18.8572 8 18.6837 8"
      stroke={fill}
    />
  </SvgIcon>
);

export default ChevronDownIcon;
