import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { BodyRegular, H3 } from '../Typography/Typography';
import { DefaultButton } from '../Button/Button';
import TopicTag from '../TopicTag/TopicTag';
import { filterBlogTags } from '../../utils/utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: '#fff',
      borderRadius: '0 16px 16px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      padding: '12px',
      marginBottom: '32px',
      [theme.breakpoints.up(697)]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px',
        marginBottom: '64px',
      },
    },
    image: {
      borderRadius: '0 8px 8px',
      marginBottom: '16px',
      overflow: 'hidden',
      [theme.breakpoints.up(697)]: {
        width: '58%',
        marginBottom: 0,
      },
    },
    imageInner: {
      backgroundImage: ({ image }) => `url(${image?.url})`,
      paddingTop: ({ image }) =>
        image?.dimensions ? `${(image.dimensions.height / image.dimensions.width) * 100}%` : '56.25%',
      backgroundSize: 'cover',
      backgroundPosition: '0 0',
      [theme.breakpoints.up(697)]: {
        paddingTop: () => '75.84%',
      },
    },
    content: {
      [theme.breakpoints.up(697)]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '24px',
        width: '39%',
      },
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: 8,
    },
    tag: {
      backgroundColor: '#E9E9EC',
      borderRadius: '4px',
      width: 'fit-content',
      padding: '4px',
    },
    label: {
      marginBottom: 0,
    },
    h3: {
      marginBottom: 8,
    },
    bodyRegular: {
      fontSize: '13px',
      lineHeight: '16px',
      color: '#4F4F4F',
      marginBottom: 20,
      [theme.breakpoints.up(600)]: {
        fontSize: '15px',
        lineHeight: '20px',
      },
    },
    button: {
      padding: '12px',
    },
  };
});

const FeaturedBlogPost = ({ image, title, subtitle, tags = [], url }) => {
  const classes = useStyles({ image });
  const { t } = useTranslation();
  const filteredTags = filterBlogTags(tags);
  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <Link to={url}>
          <div className={classes.imageInner} />
        </Link>
      </div>
      <div className={classes.content}>
        <div className={classes.tagsContainer}>
          {filteredTags.map((tag) => {
            return <TopicTag key={tag} topic={tag} />;
          })}
        </div>
        <H3 className={classes.h3}>{title}</H3>
        <BodyRegular className={classes.bodyRegular}>{subtitle}</BodyRegular>
        <DefaultButton variant="default" className={classes.button} to={url}>
          {t('featuredBlogPost.readArticle')}
        </DefaultButton>
      </div>
    </div>
  );
};

export default FeaturedBlogPost;
