module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TXWVRFZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"],"defaultLanguage":"en-US","siteUrl":"https://uptime.app","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"fallbackLng":"en-US"},"pages":[{"matchPath":"/:lang?/hacks/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/hack/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/collections/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/collection/(.*)","getLanguageFromPath":true,"languages":[]},{"matchPath":"/:lang?/terms-and-conditions","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"]},{"matchPath":"/:lang?/privacy-policy","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"]},{"matchPath":"/:lang?/cookie-policy","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"]},{"matchPath":"/:lang?/optum","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"]},{"matchPath":"/:lang?/download","languages":["de-DE","en-US","es","fr-CA","fr-FR","hi-IN","it-IT","ko-KR","ms-MY","nb-NO","nl-NL","pl-PL","pt-BR","ru-RU","sv-SE","th-TH","tr-TR","zh-CN","zh-TW"]},{"matchPath":"/(.*)","languages":["en-US"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@zachsz/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"uptime-website","accessToken":"MC5YZFplVGhFQUFDTUFRT2cz.YyZkZO-_vSbvv70z77-977-9Be-_ve-_ve-_vVdQ77-977-9P--_ve-_vXIb77-9AO-_ve-_ve-_ve-_vXLvv73vv70","promptForAccessToken":true,"apiEndpoint":"https://uptime-website.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Uptime","start_url":"/","background_color":"#f8f8f8","theme_color":"#EA0053","display":"minimal-ui","icon":"static/img/uptime-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cbf71380ef3d4e748d06dc758e268b0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
