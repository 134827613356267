import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import BlogPostItem from '../components/BlogPostItem/BlogPostItem';
import { H1 } from '../components/Typography/Typography';
import { tan6 } from '../utils/utils';
import FeaturedBlogPost from '../components/FeaturedBlogPost/FeaturedBlogPost';
import BlogTopicFilter from '../components/BlogTopicFilter/BlogTopicFilter';
import { SecondaryButton } from '../components/Button/Button';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
// import JoinEmailList from '../components/JoinEmailList/JoinEmailList';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
    },
    heroContainer: {
      backgroundColor: theme.palette.secondary.main,
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0 0`,
      borderRadius: '0 0 25px 50px',
    },
    heroContainerContent: {
      transform: 'skewY(6.733deg)',
      padding: '20px 24px 0',
      minHeight: 300,
      margin: '0 auto',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      [theme.breakpoints.up(600)]: {
        paddingTop: '66px',
        maxWidth: '1218px',
        minHeight: 300,
      },
      [theme.breakpoints.up(1200)]: {
        minHeight: '500px',
      },
    },
    h1: {
      color: '#fff',
      marginBottom: 0,
    },
    bodyRegular: {
      marginBottom: '16px',
    },
    container: {
      position: 'relative',
      marginTop: -300 + 20 + 30 + 8,
      padding: '0 24px',
      [theme.breakpoints.up(600)]: {
        marginTop: -300 + 66 + 40 + 16,
      },
      [theme.breakpoints.up(697)]: {
        maxWidth: '1218px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up(1200)]: {
        marginTop: -500 + 66 + 40 + 16,
      },
    },
    postContainer: {
      padding: '0 24px',
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up(697)]: {
        padding: 0,
        margin: 0,
        flex: '0 0 calc(50% - 12px)',
      },
      [theme.breakpoints.up(1025)]: {
        flex: '0 0 calc(33.333333% - 16px)',
      },
    },
    blogPosts: {
      marginBottom: '32px',
      [theme.breakpoints.up(697)]: {
        padding: '0 24px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '24px',
        maxWidth: '1218px',
        margin: '0 auto 40px',
      },
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '32px',
      [theme.breakpoints.up(697)]: {
        marginBottom: '85px',
      },
    },
    downloadComponent: {
      padding: '40px 24px',
      margin: '16px 0 40px',
    },
  };
});

const BlogHomePage = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const node = data?.prismicBlogHomePage;
  const [topic, setTopic] = useState('');
  const [totalPosts, setTotalPosts] = useState(9);
  const [blogPosts, setBlogPosts] = useState([]);
  const isMd = useMediaQuery('screen and (min-width: 697px)');

  useEffect(() => {
    setBlogPosts(data?.allPrismicBlogPost?.edges?.slice(0, totalPosts));
  }, [totalPosts, setBlogPosts, data?.allPrismicBlogPost?.edges]);

  const featuredPost =
    data?.allPrismicBlogPost?.edges?.find(({ node: blogPost }) => {
      return blogPost?.tags?.includes('featured');
    }) || blogPosts[0];
  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar />
      <main className={classes.root}>
        <div className={classes.heroContainer}>
          <div className={classes.heroContainerContent}>
            <H1 className={classes.h1}>{node?.data?.title?.text}</H1>
          </div>
        </div>
        <div className={classes.container}>
          <FeaturedBlogPost
            image={featuredPost?.node?.data?.image}
            title={featuredPost?.node?.data?.title?.text}
            subtitle={featuredPost?.node?.data?.subtitle?.text}
            tags={featuredPost?.node?.tags}
            url={`/blog/${featuredPost?.node?.slugs[0]}`}
          />
          <BlogTopicFilter
            selectedTopic={topic}
            handleOnChange={(ev) => {
              setTopic(ev.target.value);
            }}
          />
        </div>
        <div className={classes.blogPosts}>
          {blogPosts?.map(({ node: blogPost }, index) => {
            const renderDownloadComponent = index !== blogPosts?.length - 1 && (index + 1) % 3 === 0;
            return (
              <>
                <div className={classes.postContainer}>
                  <BlogPostItem
                    key={blogPost?.id}
                    id={blogPost?.id}
                    image={blogPost?.data?.image}
                    title={blogPost?.data?.title?.text}
                    date={blogPost?.data?.publish_date}
                    tags={blogPost?.tags}
                    url={`/blog/${blogPost?.slugs[0]}`}
                  />
                </div>
                {!isMd && renderDownloadComponent && <DownloadComponent className={classes.downloadComponent} />}
              </>
            );
          })}
        </div>
        <div className={classes.loadMoreContainer}>
          <SecondaryButton
            onClick={() => {
              if (data?.allPrismicBlogPost?.edges?.length > totalPosts) {
                setTotalPosts(totalPosts + 9);
              }
            }}
          >
            Load more
          </SecondaryButton>
        </div>
        <DownloadComponent showPhones />
        {/* <JoinEmailList /> */}
      </main>
      <Footer />
    </>
  );
};

export default withPrismicPreview(BlogHomePage);

export const query = graphql`
  query BlogPageQuery($id: String, $language: String) {
    prismicBlogHomePage(id: { eq: $id }) {
      _previewable
      id
      prismicId
      data {
        title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
    allPrismicBlogPost(sort: { fields: data___publish_date, order: DESC }) {
      edges {
        node {
          id
          prismicId
          tags
          slugs
          data {
            title {
              text
            }
            subtitle {
              text
            }
            image {
              url
              alt
              dimensions {
                width
                height
              }
              gatsbyImageData(width: 960)
            }
            publish_date
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
