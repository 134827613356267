import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { FormGroup, FormControlLabel, Checkbox, Typography, Button } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input/input';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Modal from '../components/Modal/Modal';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import { getPlaceHolderHacks, tan6 } from '../utils/utils';
import 'react-phone-number-input/style.css';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { BodyMedium, Caption, H3 } from '../components/Typography/Typography';
import { getHacks } from '../utils/api';
import CurrencySelector from '../components/CurrencySelector/CurrencySelector';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import AppStoreRatingIcon from '../components/icons/awards/AppStoreRatingIcon';
import GoogleIcon from '../components/icons/awards/GoogleIcon';
import AppleAwardIcon from '../components/icons/awards/AppleAwardIcon';
import FastCompanyIcon from '../components/icons/awards/FastCompanyIcon';
import GoogleStoreRating from '../components/icons/awards/GoogleStoreRatingIcon';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
      position: 'relative',
      flex: '1 0 auto',
      '& > div > div': {
        padding: '24px',
      },
    },
    heroContainer: {
      position: 'relative',
      zIndex: '10',
      backgroundColor: theme.palette.secondary.main,
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      // marginBottom: `calc((100vw * ${tan6} * -1) / 2)`,
      marginBottom: '-60px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '0 0 25px 50px',
      overflow: 'hidden',
      [theme.breakpoints.up(1008)]: {
        // marginBottom: `calc((1008px * ${tan6} * -1) / 2)`,
        marginBottom: `-150px`,
        paddingBottom: `calc((1008px * ${tan6} * -1) / 2)`,
      },
    },
    heroContainerContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '76px 24px 0',
      transform: 'skewY(6.733deg)',
      maxWidth: 600,
      margin: '0 auto',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      [theme.breakpoints.up(1008)]: {
        display: 'flex',
        padding: '100px 24px 57px',
        maxWidth: 1008,
      },
    },
    heroInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '64px',
      [theme.breakpoints.up(1008)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }
    },
    heroTextContainer: {
      marginBottom: '32px',
      [theme.breakpoints.up(1008)]: {
        marginBottom: '0',
        maxWidth: '498px',
      },
    },
    heroTitle: {
      color: '#fff',
      fontSize: '34px',
      lineHeight: '40px',
      marginBottom: '32px',
      [theme.breakpoints.up(1008)]: {
        fontSize: '60px',
        lineHeight: '71px',
      }
    },
    discountLabel: {
      display: 'inline-block',
      backgroundColor: '#1F2744',
      color: '#FFF',
      padding: '2px 12px',
      borderRadius: '4px',
      fontWeight: '700',
      lineHeight: '24px',
      fontSize: '20px',
      marginBottom: '16px',
    },
    headerSubtitle: {
      marginBottom: '22px',
    },
    awards: {
      display: 'block',
      width: '100%',
      maxWidth: '797px',
      margin: '0 auto',
    },
    awardsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '24px',
      alignItems: 'center',
      padding: '0 24px 0',
      opacity: '0.8',
      [theme.breakpoints.up(1006)]: {
        padding: 0,
        maxWidth: '797px',
        width: '100%',
        margin: '0 auto',
        justifyContent: 'space-between',
      },
    },
    awardTextBottom: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '500',
      color: '#fff',
    },
    awardTextRight: {
      display: 'block',
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '500',
      color: '#fff',
      whiteSpace: 'pre-line',
      flexShrink: '0',
    },
    infoContainer: {
      width: '100%',
      maxWidth: '386px',
    },
    infoContainerInner: {
      backgroundColor: '#FFF',
      borderRadius: '0 16px 16px',
      boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    acceptanceText: {
      marginBottom: '8px',
    },
    logoContainer: {
      padding: '48px 0',
      backgroundColor: ({ image_background_color }) => image_background_color || 'none',
    },
    logo: {
      display: 'block',
      maxWidth: '250px',
      margin: '0 auto',
    },
    infoContainerContent: {
      padding: '24px 24px 32px',
    },
    h3: {
      marginBottom: '16px',
    },
    caption: {
      marginBottom: '16px',
      '&:last-of-type': {
        marginBottom: '40px',
      },
    },
    phoneInput: {
      backgroundColor: '#E9E9EC',
      color: theme.palette.primary.main,
      padding: '12px 16px',
      borderRadius: '0 8px 8px',
      width: '100%',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: '15px',
      lineHeight: '20px',
      border: 0,
      marginBottom: '8px',
      '&::placeholder': {
        color: theme.palette.primary.main,
      },
      '&:focus-visible': {
        outline: 0,
      },
    },
    errorText: {
      fontSize: '11px',
      lineHeight: '13px',
      color: 'red',
      marginBottom: '8px',
    },
    checkboxGroup: {
      marginBottom: '8px',
    },
    formControlLabel: {
      marginLeft: '0',
    },
    checkboxLabel: {
      display: 'inline',
      marginLeft: '8px',
    },
    tandCButton: {
      padding: 0,
      fontSize: '13px',
      lineHeight: '16px',
      textDecoration: 'underline',
      color: '#4F4F4F',
      fontWeight: '400',
    },
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      padding: '12px',
      fontSize: '17px',
      lineHeight: '22px',
      borderRadius: '0px 8px 8px',
      boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  };
});

const placeholderHacks = getPlaceHolderHacks();

const PromoCode = ({ data, location, pageContext }) => {
  const node = data?.prismicPromoCodePage;
  const classes = useStyles({ image_background_color: node?.data?.image_background_color });
  const { language } = useI18next();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const [hacks, setHacks] = useState(placeholderHacks);
  const currencies = node?.data?.currencies || [];
  const [selectedPriceId, setSelectedPriceId] = useState('');
  const titleImage = getImage(node?.data?.title_image);

  const queryParams = new URLSearchParams(location.search);
  const accessCode = node?.data?.access_code?.text;

  useEffect(() => {
    if (node?.data?.skip_terms_and_conditions) {
      setTermsAndConditionsChecked(true);
    }
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      setSelectedPriceId(currencies[0].stripe_price_id1.text);
    } else {
      setSelectedPriceId(node?.data?.stripe_price_id?.text || '');
    }
  }, [currencies, node.data.stripe_price_id.text]);

  useEffect(() => {
    getHacks({ filter, setHacks, placeholderHacks, language: language.split('-')[0] });
  }, [setHacks, filter]);

  const handleNumberChange = (val) => {
    setPhoneError(false);
    if (val?.length < 2) {
      setPhoneNumber('');
    }
    setPhoneNumber(val);
  };

  const handleClaimOffer = () => {
    if (node?.data?.has_phone_input_field) {
      if (phoneNumber?.length !== 13) {
        setPhoneError(true);
      } else if (termsAndConditionsChecked) {
        window.location.href = `${node?.data?.button_link?.text}/?promoCode=${node?.data?.promo_code?.text}&campaign=${node?.data?.campaign_name?.text
          }&campaignData=${encodeURIComponent(phoneNumber)}&selectedPlan=${selectedPriceId}${accessCode ? `&access_code=${accessCode}` : ''}&${queryParams.toString()}`;
      } else {
        setTermsError(true);
      }
    } else {
      if (termsAndConditionsChecked) {
        window.location.href = `${node?.data?.button_link?.text}/?selectedPlan=${selectedPriceId}&promoCode=${node?.data?.promo_code?.text}&campaign=${node?.data?.campaign_name?.text}${accessCode ? `&access_code=${accessCode}` : ''}&${queryParams.toString()}`;
      } else {
        setTermsError(true);
      }
    }
  };

  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar overrideButtons={<></>} disableBurgerMenu keepBackgroundColor />
      <main className={classes.root}>
        <div className={classes.heroContainer}>
          <div className={classes.heroContainerContent}>
            <div className={classes.heroInner}>
              <div className={classes.heroTextContainer}>
                {node?.data?.discount_label?.text && (
                  <div className={classes.discountLabel}>{node?.data?.discount_label?.text}</div>
                )}
                <Typography variant="h1" className={classes.heroTitle}>{node?.data?.hero_title?.text || t('promoCodePage.title.fallback')}</Typography>
                {node?.data?.hero_text?.raw ? (
                  node?.data?.hero_text?.raw?.map((node) => {
                    if (node.type === 'paragraph') {
                      return (
                        <BodyMedium key={node.text} color="#fff" className={classes.headerSubtitle}>
                          {node.text}
                        </BodyMedium>
                      );
                    }
                    return null;
                  })
                ) : (
                  <BodyMedium color="#fff" className={classes.headerSubtitle}>
                    {t('promoCodePage.headerSubtitle')}
                  </BodyMedium>
                )}
              </div>
              <div className={classes.infoContainer}>
                <div className={classes.infoContainerInner}>
                  <div className={classes.logoContainer}>
                    <GatsbyImage
                      className={classes.logo}
                      image={titleImage as IGatsbyImageData}
                      alt={node?.data?.title_image.alt || ''}
                    />
                  </div>
                  <div className={classes.infoContainerContent}>
                    <H3 className={classes.h3}>{node?.data?.box_header?.text}</H3>
                    {node?.data?.box_text?.raw?.map((node) => {
                      if (node.type === 'paragraph') {
                        return (
                          <Caption key={node.text} className={classes.caption} color="#4F4F4F">
                            {node.text}
                          </Caption>
                        );
                      }
                      return null;
                    })}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {node?.data?.has_phone_input_field && (
                        <>
                          <PhoneInput
                            className={classes.phoneInput}
                            placeholder={node?.data?.phone_number_input_placeholder?.text}
                            value={phoneNumber}
                            onChange={handleNumberChange}
                            country="GB"
                          />
                          {phoneError && (
                            <span className={classes.errorText}>
                              {t('promoCodePage.phoneErrorText')}
                            </span>
                          )}
                        </>
                      )}

                      {currencies.length > 1 && (
                        <CurrencySelector
                          value={selectedPriceId}
                          currencies={currencies.map((currency) => {
                            return {
                              value: currency.stripe_price_id1.text,
                              img: currency.currency_image?.url,
                              text: currency.currency_code.text.toUpperCase(),
                            };
                          })}
                          onChange={(priceId) => {
                            setSelectedPriceId(priceId);
                          }}
                        />
                      )}
                      {node?.data?.skip_terms_and_conditions ? (
                        <Caption className={classes.acceptanceText} color="#4f4f4f">
                          {t('promoCodePage.acceptanceText')}
                          <Button
                            className={classes.tandCButton}
                            onKeyDown={() => setModalIsOpen(true)}
                            tabIndex={0}
                            onClick={() => setModalIsOpen(true)}
                          >
                            {t('promoCodePage.tandCButton')}
                          </Button>
                        </Caption>
                      ) : (
                        <FormGroup className={classes.checkboxGroup}>
                          <FormControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Checkbox
                                color="primary"
                                onClick={() => {
                                  setTermsError(false);
                                  setTermsAndConditionsChecked(!termsAndConditionsChecked);
                                }}
                                checked={termsAndConditionsChecked}
                              />
                            }
                            label={
                              <div>
                                <Caption className={classes.checkboxLabel} color="#4f4f4f">
                                  {t('promoCodePage.acceptCaption')}
                                </Caption>
                                <Button
                                  className={classes.tandCButton}
                                  onKeyDown={() => setModalIsOpen(true)}
                                  tabIndex={0}
                                  onClick={() => setModalIsOpen(true)}
                                >
                                  {t('promoCodePage.acceptButton')}
                                </Button>
                              </div>
                            }
                          />
                        </FormGroup>
                      )}

                      <Button
                        className={classes.button}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleClaimOffer}
                      >
                        {node?.data?.button_text?.text}
                      </Button>
                      {termsError && (
                        <span className={classes.errorText}>{t('promoCodePage.tAndCError')}</span>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.awardsContainer}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <AppStoreRatingIcon color="#fff" />
                <span className={classes.awardTextBottom}>{t('downloadPage.awards.appleRating')}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', }}>
                <GoogleIcon color="#fff" />
                <span className={classes.awardTextRight}>{t('downloadPage.awards.googleAward')}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', }}>
                <AppleAwardIcon color="#fff" />
                <span className={classes.awardTextBottom}>{t('downloadPage.awards.appleAward')}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', }}>
                <FastCompanyIcon color="#fff" />
                <span className={classes.awardTextRight}>{t('downloadPage.awards.fastCompany')}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <GoogleStoreRating color="#fff" />
                <span className={classes.awardTextBottom}>{t('downloadPage.awards.googleRating')}</span>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer paddingTop="270px" />
      <Modal
        hideAccept={node?.data?.skip_terms_and_conditions}
        body={node?.data?.terms_and_conditions.richText}
        title={t('promoCodePage.tAndCModalTitle')}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onAccept={() => {
          setTermsError(false);
          setTermsAndConditionsChecked(true);
        }}
      />
    </>
  );
};

export default withPrismicPreview(PromoCode);

export const query = graphql`
  query PromoCodeQuery($id: String, $language: String) {
    prismicPromoCodePage(id: { eq: $id }) {
      _previewable
      id
      prismicId
      data {
        discount_label {
          text
        }
        hero_title {
          text
        }
        hero_text {
          raw
        }
        title_image {
          alt
          gatsbyImageData(width: 400)
          dimensions {
            width
          }
        }
        image_background_color
        button_link {
          text
        }
        button_text {
          text
        }
        campaign_name {
          text
        }
        box_header {
          text
        }
        box_text {
          raw
        }
        phone_number_input_placeholder {
          text
        }
        download_button_label {
          text
        }
        stripe_price_id {
          text
        }
        has_phone_input_field
        terms_and_conditions {
          richText
        }
        promo_code {
          text
        }
        testimonial_header {
          text
        }
        testimonial {
          testimonial_title {
            text
          }
          body {
            text
          }
        }
        skip_terms_and_conditions
        access_code {
          text
        }
        currencies {
          currency_code {
            text
          }
          stripe_price_id1 {
            text
          }
          currency_image {
            url
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
    prismicLandingV3 {
      data {
        library_title {
          text
        }
        screenshots_title {
          text
        }
        screenshot_carousel {
          text {
            text
          }
          title {
            text
          }
          image {
            alt
            gatsbyImageData(width: 245, imgixParams: { lossless: true })
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
