import React, { FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const colorMap = {
  book: '#00875A',
  course: '#C431C4',
  documentary: '#007FA6',
  podcast: '#7F47CC',
  original: '#1F2744',
  up: '#1F2744',
};

const useStyles = makeStyles(() => {
  return {
    root: {
      width: 'fit-content',
      padding: '1px 4px',
      textTransform: 'uppercase',
      color: '#fff',
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: '700',
      marginBottom: '4px',
      letterSpacing: '0.098px',
      backgroundColor: ({ hackType }: { hackType: HackLabelProps['hackType'] }) => colorMap[hackType],
    },
  };
});

export type HackLabelProps = {
  className?: string;
  hackType: 'book' | 'course' | 'documentary' | 'podcast' | 'original' | 'up';
}

const HackLabel: FC<HackLabelProps> = ({
  className,
  hackType = 'book'
}) => {
  const classes = useStyles({ hackType });
  const { t } = useTranslation();
  return (
    <div className={classnames(classes.root, className)}>
      {t(`hackPage.label.${hackType}`)}
    </div>
  )
}

export default HackLabel;
