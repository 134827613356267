import React, { useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { H3 } from '../Typography/Typography';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      marginBottom: 32,
      [theme.breakpoints.up(697)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '40px',
        paddingBottom: '16px',
      },
    },
    h3: {
      [theme.breakpoints.up(697)]: {
        marginBottom: 0,
      },
    },
    container: {
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up(697)]: {
        maxWidth: '304px',
      },
    },
    select: {
      appearance: 'none',
      backgroundColor: '#fff',
      border: 0,
      borderRadius: '0px 8px 8px',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      padding: '12px',
      fontFamily: 'Rubik, sans-serif',
      fontSize: '15px',
      lineHeight: '24px',
      width: '100%',
    },
    chevron: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      pointerEvents: 'none',
    },
  };
})

const BlogTopicFilter = ({
  selectedTopic,
  handleOnChange,
  topics = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const selectRef = useRef();
  return (
    <div className={classes.root}>
      <H3 className={classes.h3}>{t('blogTopicFilter.title')}</H3>
      <div className={classes.container}>
        <select
          className={classes.select}
          ref={selectRef}
          name="blog-topic-filter"
          value={selectedTopic}
          onChange={handleOnChange}
        >
          <option>{t('blogTopicFilter.optionAll')}</option>
          {topics.map(topic => {
            return (
              <option key={topic} value={topic}>
                {topic}
              </option>
            );
          })}
        </select>
        <ChevronDownIcon className={classes.chevron} />
      </div>
    </div>
  )
}

export default BlogTopicFilter;
