import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
      marginBottom: '40px',
      [theme.breakpoints.up(1200)]: {
        backgroundColor: '#FFF',
        padding: '64px 160px 64px 52px',
        marginBottom: '0',
        width: '650px',
        height: '483px',
        clipPath: 'path(\'m 0 0 h 571.116 c 39.662 0 68.412 37.7976 57.821 76.0205 l -100.575 362.9995 c -7.201 25.989 -30.855 43.98 -57.822 43.98 h -470.54 v -483 z\')'
      }
    },
    step: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
    },
    stepNumberContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '8px',
    },
    stepNumber: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: '#FFF',
      width: '58px',
      height: '58px',
      border: '4px solid #E9E9EC',
      borderRadius: '100%',
    },
    divider: {
      flex: '1',
      width: '4px',
      backgroundColor: '#E9E9EC',
    },
    stepTextContainer: {
      paddingTop: '15px'
    },
    stepTitle: {
      display: 'block',
      fontSize: '19px',
      lineHeight: '28px',
      color: theme.palette.primary.main,
      fontWeight: '500',
      marginBottom: '8px',
    },
    stepText: {
      display: 'block',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#4F4F4F',
      fontWeight: '400',
    },
  };
});

const HowItWorksSteps = ({
  steps = []
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {steps.map((step, index) => {
        return (
          <div className={classes.step}>
            <div className={classes.stepNumberContainer}>
              <div className={classes.stepNumber}>{index + 1}</div>
              {index < steps.length - 1 && <div className={classes.divider} />}
            </div>
            <div className={classes.stepTextContainer}>
              <span className={classes.stepTitle}>{t(`creatorsPage.howItWorks.steps.${index + 1}.title`)}</span>
              <span className={classes.stepText}>{t(`creatorsPage.howItWorks.steps.${index + 1}.text`)}</span>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default HowItWorksSteps;
