/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';

const useCriteoScript = () => {
  let deviceType;

  if (typeof window !== 'undefined') {
    deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
      ? 'm'
      : 'd';
  }

  const criteoPageView = () => {
    useEffect(() => {
      (window as any).criteo_q = (window as any).criteo_q || [];

      (window as any).criteo_q.push(
        { event: 'setAccount', account: [92655, 92496] },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewItem', item: 'UptimeDownloads' },
      );
    }, []);
  };

  const trackCriteoButtonClick = () => {
    (window as any).criteo_q = (window as any).criteo_q || [];

    (window as any).criteo_q.push(
      { event: 'setAccount', account: [92655, 92496] },
      { event: 'setSiteType', type: deviceType },
      {
        event: 'trackTransaction',
        id: Math.floor(Math.random() * 99999999999) + 1,
        item: [{ id: 'UptimeDownloads', price: '1', quantity: '1' }],
      },
    );
  };

  return { criteoPageView, trackCriteoButtonClick };
};

export default useCriteoScript;
