import React from 'react';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/styles/makeStyles';
import PressPageArticle from '../components/PressPageArticle/PressPageArticle';
import TopContainer from '../components/TopContainer/TopContainer';
import BottomSection from '../components/BottomSection/BottomSection';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
    },
    heroTextContainer: {
      [theme.breakpoints.up(600)]: {
        width: '53%',
      },
      [theme.breakpoints.up(1200)]: {
        width: '50%',
      },
    },
    heroImage: {
      display: 'block',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(600)]: {
        height: 'fit-content',
        width: '47%',
      },
      [theme.breakpoints.up(682)]: {
        width: '50%',
        marginBottom: 0,
      },
    },
    container: {
      position: 'relative',
      padding: 0,
      maxWidth: 1068, // 1006 + article padding (48)
      margin: '80px auto 0',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '& a:last-child': {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up(600)]: {
        alignItems: 'flex-start',
      },
      [theme.breakpoints.up(1200)]: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(9),
        '& a:last-child': {
          marginTop: 0,
          marginLeft: theme.spacing(2),
        },
      },
    },
    articleContainer: {},
    button: {
      color: theme.palette.text.primary,
      fontSize: 19,
      fontWeight: 'bold',
      backgroundColor: '#fff',
      boxShadow: '1px 0px 18px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '0 10px 10px',
    },
  };
});

const PressPage = ({ data, location, pageContext }) => {
  const classes = useStyles();
  const node = data?.prismicPressPage;
  const subtitle = node?.data?.sub_title?.text || '';
  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar />
      <main className={classes.root}>
        <TopContainer isReverse>
          <img className={classes.heroImage} src="/img/press_page/hero.png" alt="" />
          <div className={classes.heroTextContainer}>
            <Typography className={classes.h1} variant="h1">
              {node?.data?.title?.text}
            </Typography>
            <Typography className={classes.h2} variant="subtitle1">
              {subtitle.replace(new RegExp(String.fromCharCode(160), 'g'), ' ')}
            </Typography>
            <div className={classes.buttonContainer}>
              <Button className={classes.button} variant="contained" href={node?.data?.press_kit?.url}>
                {node?.data?.press_kit_button_label?.text}
              </Button>
              <Button className={classes.button} variant="contained" href="mailto:info@uptime.app">
                {node?.data?.get_in_touch_button_label?.text}
              </Button>
            </div>
          </div>
        </TopContainer>
        <div className={classes.container}>
          <div className={classes.articleContainer}>
            <Grid container spacing={6} justifyContent="center">
              {node?.data?.articles.map(article => {
                return (
                  <Grid className={classes.gridItem} key={article.article_link.url} item>
                    <PressPageArticle
                      date={article.article_date}
                      title={article.article_title.text}
                      text={article.article_text.text}
                      url={article.article_link.url}
                      image={article.article_image}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <BottomSection bgColor="#03091D" />
      </main>
      <Footer />
    </>
  );
};

export default withPrismicPreview(PressPage);

export const query = graphql`
  query PressPageQuery($id: String, $language: String) {
    prismicPressPage(id: { eq: $id }) {
      _previewable
      id
      prismicId
      data {
        title {
          text
        }
        sub_title {
          text
        }
        press_kit_button_label {
          text
        }
        press_kit {
          url
        }
        get_in_touch_button_label {
          text
        }
        articles {
          article_date
          article_title {
            text
          }
          article_text {
            text
          }
          article_link {
            url
          }
          article_image {
            alt
            url
          }
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
