import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { tan6 } from '../../utils/utils';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    topContainer: {
      backgroundColor: '#EA0053',
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      marginBottom: `calc((100vw * ${tan6} * -1) / 2)`,
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '0 0 25px 50px',
      hyphens: 'auto',
      [theme.breakpoints.up(1006)]: {
        marginBottom: `calc((1006px * ${tan6} * -1) / 2)`,
        paddingBottom: `calc((1006px * ${tan6} * -1) / 2)`,
      },
    },
    topContainerContent: {
      padding: '20px 25px 0',
      transform: 'skewY(6.733deg)',
      maxWidth: 1006,
      margin: '0 auto',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      '& h1': {
        color: '#fff',
        fontSize: 50,
        lineHeight: '60px',
        letterSpacing: -2.57,
        textAlign: 'center',
      },
      '& h6': {
        color: '#fff',
        fontSize: 18,
        lineHeight: '21.33px',
        marginBottom: theme.spacing(2),
        wordBreak: 'break-word',
        textAlign: 'center',
      },
      [theme.breakpoints.up(600)]: {
        alignItems: 'center',
        padding: '80px 50px 0',
        '& h1': {
          fontSize: 72,
          lineHeight: '85.32px',
          marginBottom: '30px',
          textAlign: 'left',
        },
        '& h6': {
          fontSize: 18,
          lineHeight: '21.33px',
          marginBottom: '30px',
          textAlign: 'left',
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: '100px 0 0',
        '& h1': {
          fontSize: 76,
          lineHeight: '90.06px',
          marginBottom: '30px',
        },
        '& h6': {
          fontSize: 18,
          lineHeight: '21.33px',
          marginBottom: '30px',
        },
      },
    },
    reverse: {
      [theme.breakpoints.up(600)]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      },
    },
    limitTextWidth: {
      [theme.breakpoints.up(1200)]: {
        '& h6': {
          maxWidth: 301,
        },
      },
    },
    removeMarginBottom: {
      marginBottom: 0,
      [theme.breakpoints.up(1006)]: {
        marginBottom: 0,
      },
    },
  };
});

const TopContainer = ({ children, isReverse, limitTextWidth, removeMarginBottom, className }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.topContainer, removeMarginBottom ? classes.removeMarginBottom : '')}>
      <div
        className={classnames(
          classes.topContainerContent,
          isReverse ? classes.reverse : '',
          limitTextWidth ? classes.limitTextWidth : '',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default TopContainer;
