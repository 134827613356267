import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      maxWidth: 1008,
      width: '100%',
      [theme.breakpoints.down(1008)]: {
        display: 'none',
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '48px',
      '& h3': {
        fontSize: '25px',
        lineHeight: '34px',
        fontWeight: '500',
        marginBottom: '12px',
        color: theme.palette.primary.main,
      },
      '&:nth-child(even)': {
        flexDirection: 'row-reverse',
      },
      '&:last-child': {
        marginBottom: '0',
      },
    },
    imageContainer: {
      width: '412px',
    },
    image: {
      display: 'block',
      width: '100%',
    },
    textLeft: {
      width: '465px',
    },
    bodyText: {
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
  };
});

const FeatureItem = ({ classes, item, index }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.item}>
      <div className={classes.textLeft}>
        <Typography variant="h3">{item.title}</Typography>
        <Typography className={classes.bodyText} variant="body1">
          {item.text}
        </Typography>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={item.image} alt="" />
      </div>
    </div>
  );
};

const FeaturesList = ({ items = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        return <FeatureItem key={item.title} classes={classes} item={item} index={index} />;
      })}
    </div>
  );
};

export default FeaturesList;
