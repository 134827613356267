import React, { useEffect, useRef, useState } from 'react';
import MD5 from 'spark-md5';
import useWindowSize from '../../hooks/useWindowSize';
import './styles.css';

const getBlurredPodcastBG = (url: string) => {
  const path = `/${encodeURIComponent(
    url,
  )}?blur=1000&fit=crop&w=200&h=308&fm=webp`;
  const signature = MD5.hash(`Kb4sFGUZpR33D5b8${path}`);

  return `https://uptime-ext.imgix.net${path}&s=${signature}`;
};

const PodcastCover = ({
  imageUrl,
  title,
}) => {
  const containerRef = useRef(null);
  const { width } = useWindowSize();
  const [labelSize, setLabelSize] = useState({
    fontSize: '10px',
    lineHeight: '14px',
  });
  const [titleSize, setTitleSize] = useState({
    fontSize: '15px',
    lineHeight: '20px',
  })

  useEffect(() => {
    if (containerRef.current) {
      setLabelSize({
        fontSize: `${containerRef.current.offsetWidth * 0.078}px`,
        lineHeight: `${containerRef.current.offsetWidth * 0.1}px`,
      });
      setTitleSize({
        fontSize: `${containerRef.current.offsetWidth * 0.1}px`,
        lineHeight: `${containerRef.current.offsetWidth * 0.14}px`,
      });
    }
  }, [containerRef.current, width])
  return (
    <div
      ref={containerRef}
      className="podcast-cover"
      style={{ backgroundImage: `url("${getBlurredPodcastBG(imageUrl)}")` }}
    >
      <div className="podcast-cover__overlay" />
      <img className="podcast-cover__thumbnail" src={imageUrl} alt={title} />
      <span className="podcast-cover__label" style={labelSize}>
        Episode
      </span>
      <span className="podcast-cover__podcast-title" style={titleSize}>
        {title}
      </span>
    </div>
  )
}

export default PodcastCover;