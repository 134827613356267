import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { Label } from '../Typography/Typography';

const useStyles = makeStyles(() => {
  return {
    tag: {
      backgroundColor: '#E9E9EC',
      borderRadius: '4px',
      width: 'fit-content',
      padding: '4px',
    },
    label: {
      marginBottom: 0,
    },
  }
});

const TopicTag = ({
  topic,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.tag}>
      <Label className={classes.label}>{topic}</Label>
    </div>
  );
}

export default TopicTag;
