import * as React from 'react';
import { graphql } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import TopContainer from '../components/TopContainer/TopContainer';
import BottomSection from '../components/BottomSection/BottomSection';
import AppBar from '../components/AppBar/AppBar';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import LegalFooter from '../components/Footer/LegalFooter';
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next';
import LanguageSwitcher, { languageMap } from '../components/LanguageSwitcher/LanguageSwitcher';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
    },
    heroImage: {
      display: 'block',
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(600)]: {
        height: 'fit-content',
        width: '40%',
        flexShrink: 0,
      },
      [theme.breakpoints.up(682)]: {
        width: '50%',
        marginBottom: 0,
      },
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 25px',
      maxWidth: 1006,
      margin: '0 auto',
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: 22,
        lineHeight: '26.07px',
        fontWeight: 700,
        letterSpacing: -0.5,
        textAlign: 'center',
        marginBottom: 20,
      },
      [theme.breakpoints.up(600)]: {
        padding: '0 50px',
        '& h2': {
          fontSize: 36,
          lineHeight: '42.66px',
        },
      },
      [theme.breakpoints.up(1200)]: {
        padding: `20px 0 0`,
        '& h2': {
          fontSize: 44,
          lineHeight: '50px',
          marginBottom: theme.spacing(6),
        },
      },
    },
    description: {
      fontSize: '16px',
      lineHeight: '1.5',
      '$title + &': {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
      },
      '& h5': {
        fontSize: '24px',
      },
      '& h6': {
        fontSize: '20px',
      },
      '& h5, & h6': {
        marginBottom: theme.spacing(2),
      },
      '& p': {
        marginBottom: theme.spacing(3),
      },
      '& b, & strong': {
        fontWight: 'bold',
      },
      '& a': {
        color: theme.palette.primary.main,
      },
      '& li': {
        ...theme.typography.body1,
      },
    },
  };
});

const htmlUids = [
  'agreements',
  'terms-and-conditions-ig',
  'creators-terms-and-conditions',
  'uptime-content-policy',
  'uptime-license-agreement',
  'terms-and-conditions-quiz',
]

const PrivacyPolicyPage = ({ data, location, pageContext }) => {
  const node = data?.prismicTermsAndConditions;
  const classes = useStyles();
  const heroImage = getImage(node?.data?.hero_image);
  const { t } = useTranslation();
  const { language, languages, originalPath } = useI18next();
  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar overrideButtons={<LanguageSwitcher hideDivider />} removeLogoLink disableBurgerMenu />
      <main className={classes.root}>
        <TopContainer isReverse>
          <GatsbyImage className={classes.heroImage} image={heroImage as IGatsbyImageData} alt={node?.data?.hero_image?.alt || ''} />
          <Typography variant="h1">{node?.data?.page_title?.text}</Typography>
        </TopContainer>
        <div className={classes.sectionContent}>
          <div className={classes.description} dangerouslySetInnerHTML={{ __html: htmlUids.includes(node.uid) ? node?.data?.page_description?.html : t(`${node?.data?.page_description?.text}`) }} />
          <div className={classes.description} style={{ width: '100%' }}>
            <ul>
              {languages.map((lang) => {
                if (language === lang) {
                  return null;
                }
                return <li><Link to={originalPath} language={lang}>{languageMap[lang] || t(`languageSwitcher.options.${lang}`)}</Link></li>
              })}
            </ul>
          </div>
        </div>
        <BottomSection bgColor="#03091D" />
      </main>
      <LegalFooter />
    </>
  );
};

export default withPrismicPreview(PrivacyPolicyPage);

export const query = graphql`
  query TermsAndConditionsPageQuery($uid: String, $language: String, $langRegex: String) {
    prismicTermsAndConditions(uid: { eq: $uid }, lang: { regex: $langRegex }) {
      _previewable
      id
      prismicId
      uid
      type
      lang
      first_publication_date
      last_publication_date
      slugs
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        hero_image {
          url
          alt
          dimensions {
            width
            height
          }
          gatsbyImageData( width: 503 )
        }
        title {
          text
        }
        page_title {
          text
        }
        page_description {
          html
          text
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
