import React, { useState } from 'react';
import { Link } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import BurgerMenu from './BurgerMenu';
import BurgerIcon from '../icons/BurgerIcon';
import classnames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const useStyles = makeStyles((theme: any): any => {
  return {
    root: {
      position: 'relative',
      zIndex: '999',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: ({ variant }: any) =>
        variant === 'navy' ? theme.palette.primary.main : theme.palette.secondary.main,
      [theme.breakpoints.up(2000)]: {
        backgroundColor: ({ variant, keepBackgroundColor }: any) => {
          if (keepBackgroundColor) {
            if (variant === 'navy') {
              return theme.palette.primary.main;
            }
            return theme.palette.secondary.main;
          }
          return 'unset';
        },
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: ({maxWidth}: any) => typeof maxWidth === 'string' ? maxWidth : 1218,
      padding: '28px 24px',
      boxSizing: 'border-box',
      pointerEvents: 'all',
      [theme.breakpoints.up(660)]: {
        padding: '14px 24px',
      },
    },
    logo: {
      display: 'block',
      width: 107,
      [theme.breakpoints.up(660)]: {
        width: 134,
      },
    },
    rightContainer: {
      display: 'none',
      flexDirection: 'row',
      [theme.breakpoints.up(660)]: {
        display: 'flex',
      },
    },
    textButton: {
      color: '#fff',
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: 700,
      marginRight: 30,
    },
    downloadButton: {
      backgroundColor: ({ variant }: any) =>
        variant === 'navy' ? theme.palette.secondary.main : theme.palette.primary.main,
      fontSize: 17,
      lineHeight: '22px',
      fontWieght: 700,
      padding: 12,
      minWidth: 150,
      borderRadius: '0px 8px 8px',
      '&:hover': {
        backgroundColor: ({ variant }: any) =>
          variant === 'navy' ? theme.palette.secondary.main : theme.palette.primary.main,
      },
    },
    burgerContainer: {
      [theme.breakpoints.up(660)]: {
        display: 'none',
      },
    },
    burgerButton: {
      padding: 0,
      minWidth: 'unset',
      color: '#fff',
    },
  };
});

const AppBar = ({ variant, keepBackgroundColor, overrideButtons, logoPath, logoClass, removeLogoLink, disableBurgerMenu, maxWidth }: any) => {
  const classes = useStyles({ variant, keepBackgroundColor, maxWidth }) as any;
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const logoSrc = logoPath
    ? logoPath
    : variant === 'navy'
      ? '/img/uptime-logo-dragonfruit.svg'
      : '/img/uptime-logo-white.svg';

  const handleClick = () => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const links = t('header.links').split('|');
  return (
    <header className={classes.root}>
      <div className={classes.content}>
        <div>
          {removeLogoLink ? (
            <img className={classnames(classes.logo, logoClass)} src={logoSrc} alt={t('header.logo.altText')} />
          ) : (
            <Link to="/">
              <img className={classnames(classes.logo, logoClass)} src={logoSrc} alt={t('header.logo.altText')} />
            </Link>
          )}
        </div>
        <div>
          {!disableBurgerMenu && (
            <div className={classes.burgerContainer}>
              <Button className={classes.burgerButton} onClick={handleClick}>
                <BurgerIcon />
              </Button>
              <BurgerMenu open={menuOpen} onClose={handleClose} />
            </div>
          )}
          <div className={classes.rightContainer}>
            {overrideButtons ? (
              overrideButtons
            ) : (
              <>
                <Button className={classes.textButton} variant="text" color="primary" component={Link} to="/creators">
                  {links[0]}
                </Button>
                <Button className={classes.textButton} variant="text" color="primary" href="https://web.uptime.app/login/standalone">
                  {links[1]}
                </Button>
                <Button
                  className={classes.downloadButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const form = document.querySelector('#business-form');
                    if (form) {
                      form.scrollIntoView({ behavior: 'smooth' })
                    } else {
                      window.location.href = '/';
                    }
                  }}
                >
                  {links[2]}
                </Button>
                <LanguageSwitcher />
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppBar;
