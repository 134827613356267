import React, { useState, useEffect, FC } from 'react';
import classnames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import AppBar from '../components/AppBar/AppBar';
import { getPlaceHolderHacks, tan6 } from '../utils/utils';
import HeroContainerNew from '../components/HeroContainer/HeroContainerNew';
import useCriteoScript from '../hooks/useCriteoScript';
import { getHacks } from '../utils/api';
import FeaturesListMobile from '../components/FeaturesList/FeaturesListMobile';
import FeaturesList from '../components/FeaturesList/FeaturesList';
import Footer from '../components/Footer/Footer';
import SEOHelmet from '../helmet/SEOHelmet';
import { withPrismicPreview } from '@zachsz/gatsby-plugin-prismic-previews';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import IframeResizer from 'iframe-resizer-react';
import BusinessEnquiryForm from '../components/BusinessEnquiryForm/BusinessEnquiryForm';

const useStyles = makeStyles((theme: any): any => {
  return {
    root: {
      width: '100%',
      backgroundColor: '#F8F8F8',
      overflowX: 'clip',
    },
    heroTextContainer: {
      whiteSpace: 'pre-wrap',
      marginBottom: '48px',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '501px',
        marginBottom: '0',
      },
    },
    awards: {
      display: 'block',
      width: '100%',
      maxWidth: '338px',
      marginBottom: '48px',
    },
    heroImageContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '-120px',
      justifyContent: 'center',
      [theme.breakpoints.up(1008)]: {
        justifyContent: 'flex-start',
      },
    },
    heroPhones: {
      display: 'block',
      width: '100%',
      maxWidth: '398px',
      marginTop: '-9px',
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '48px 24px 0',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
      },
    },
    hackCarouselContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '600px',
      marginTop: '32px',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
      },
    },
    libraryTitle: {
      fontSize: '27px',
      lineHeight: '36px',
      textAlign: 'left',
      marginBottom: '24px',
      color: theme.palette.primary.main,
      [theme.breakpoints.up(1200)]: {
        textAlign: 'center',
      },
    },
    libraryText: {
      fontSize: '19px',
      lineHeight: '28px',
      marginBottom: '28px',
    },
    libraryImage: {
      display: 'block',
      width: '104%',
      marginLeft: '-2%',
      margin: '0 auto 32px',
    },
    downloadButton: {
      borderRadius: '0 8px 8px',
      padding: '12px 22px',
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0',
      marginBottom: '24px',
    },
    downloadButtonNavy: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
    },
    sectionWhite: {
      backgroundColor: '#fff',
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      marginBottom: '32px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 25px 50px',
      [theme.breakpoints.up(1008)]: {
        marginBottom: `calc((1008px * ${tan6} * -1) / 2)`,
        marginTop: `calc((1008px * ${tan6} * -1) / 2)`,
        padding: `calc((1008px * ${tan6} * -1) / 2)`,
      },
    },
    sectionWhiteContent: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '48px 24px 0',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
      },
    },
    sectionNavy: {
      backgroundColor: theme.palette.primary.main,
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      marginBottom: '32px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 25px 50px',
      [theme.breakpoints.up(1008)]: {
        marginBottom: `calc((1008px * ${tan6} * -1) / 2)`,
        marginTop: `calc((1008px * ${tan6} * -1) / 2)`,
        padding: `calc((1008px * ${tan6} * -1) / 2)`,
      },
    },
    sectionNavyContent: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '108px 25px 95px',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
        padding: '76px 24px 48px',
      },
    },
    sectionNavyLight: {
      backgroundColor: '#2B355B',
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6}))`,
      marginBottom: '-60px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 25px 50px',
      [theme.breakpoints.up(1008)]: {
        marginBottom: `-90px`,
        marginTop: `calc((1008px * ${tan6}))`,
        padding: `calc((1008px * ${tan6} * -1) / 2)`,
      },
    },
    sectionNavyLightContent: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '108px 24px 95px',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1008)]: {
        maxWidth: '1008px',
        padding: '76px 24px 48px',
      },
    },
    section8Title: {
      fontSize: '34px',
      lineHeight: '48px',
      color: '#FFF',
      fontWeight: '700',
      marginBottom: '12px',
    },
    section8Text: {
      fontSize: '17px',
      lineHeight: '24px',
      color: '#FFF',
      fontWeight: '400'
    },
    section8Container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up(1008)]: {
        flexDirection: 'row',
      },
    },
    section8ContainerText: {
      maxWidth: '472px',
      width: '100%',
    },
    section8ContainerForm: {
      maxWidth: '386px',
      width: '100%',
    },
    statsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '28px',
      rowGap: '24px',
      [theme.breakpoints.up(1008)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    statTitle: {
      fontSize: '34px',
      lineHeight: '40px',
      fontWeight: '700',
      color: '#EA0053',
      textAlign: 'center',
      marginBottom: '8px',
    },
    statText: {
      fontSize: '17px',
      lineHeight: '24px',
      color: '#4F4F4F',
      textAlign: 'center',
    },
    hacksImage: {
      display: 'block',
      width: '104%',
      marginBottom: '23px',
    },
    brandsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: '36px',
      columnGap: '32px',
      flexWrap: 'wrap',
      opacity: '0.7',
      marginBottom: '48px',
    },
    brandImage: {
      display: 'block',
    },
    section3Container: {
      margin: '24px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      alignItems: 'center',
      [theme.breakpoints.up(1008)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '48px',
      },
    },
    section3Inner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(1008)]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
    },
    section3Image: {
      display: 'block',
      width: '276px',
    },
    accolades: {
      display: 'block',
      width: '100%',
      maxWidth: '600px',
      marginBottom: '48px',
    },
    section6Title: {
      fontSize: '25px',
      lineHeight: '34px',
      fontWeight: '500',
      textAlign: 'center',
      marginBottom: '32px',
      marginTop: '72px',
      color: theme.palette.primary.main,
    },
    section6Link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    section6Buttons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '324px',
      margin: '0 auto 64px',
      '& img': {
        display: 'block',
        height: '47px',
      }
    },

    bottomSectionDownloadButtons: {
      '& a': {
        display: 'block',
      },
      '& img': {
        marginBottom: 25,
        height: 50,
      },
      [theme.breakpoints.up(600)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& a': {
          marginRight: 20,
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
  };
});

const placeholderHacks = getPlaceHolderHacks();

const Index: FC<any> = ({ data, location, pageContext }) => {
  const classes = useStyles() as any;
  const { language } = useI18next();
  const { t } = useTranslation();
  const [hacks, setHacks] = useState(placeholderHacks);
  const [filter, setFilter] = useState(null);
  const { criteoPageView, trackCriteoButtonClick } = useCriteoScript();
  const node = data?.prismicLandingV3;
  const heroImageLeft = getImage(node?.data.hero_image_left);
  const heroImageRight = getImage(node?.data.hero_image_right);
  const items = [
    {
      title: t('landingPage.appHighlights.1.title'),
      text: t('landingPage.appHighlights.1.text'),
      image: '/img/home-page/highlights/highlight-1.png'
    },
    {
      title: t('landingPage.appHighlights.2.title'),
      text: t('landingPage.appHighlights.2.text'),
      image: '/img/home-page/highlights/highlight-2.png'
    },
    {
      title: t('landingPage.appHighlights.3.title'),
      text: t('landingPage.appHighlights.3.text'),
      image: '/img/home-page/highlights/highlight-3.png'
    },
    {
      title: t('landingPage.appHighlights.4.title'),
      text: t('landingPage.appHighlights.4.text'),
      image: '/img/home-page/highlights/highlight-4.png'
    },
    {
      title: t('landingPage.appHighlights.5.title'),
      text: t('landingPage.appHighlights.5.text'),
      image: '/img/home-page/highlights/highlight-5.png'
    },
  ]

  useEffect(() => {
    getHacks({ filter, setHacks, placeholderHacks, language: language.split('-')[0] });
  }, [setHacks, filter]);
  return (
    <>
      <SEOHelmet
        title={node?.data?.meta_title.text}
        description={node?.data?.meta_description.text}
        keywords={node?.data?.meta_keywords}
        location={location}
        image={node?.data?.meta_image}
        schemaKey={node?.id}
        locale={pageContext?.language}
      />
      <AppBar />
      <main className={classes.root}>
        <HeroContainerNew>
          <div className={classes.heroTextContainer}>
            <Typography variant="h1">{t('landingPage.title')}</Typography>
            <Typography variant="subtitle1">{t('landingPage.subtitle')}</Typography>
            <img className={classes.awards} src="/img/home-page/awards.svg" alt="accolades" />
            <Button
              className={classnames(classes.downloadButton, classes.downloadButtonNavy)}
              variant="contained"
              color="primary"
              onClick={() => {
                document.querySelector('#business-form').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {t('landingPage.downloadCTA')}
            </Button>
          </div>
          <div className={classes.heroImageContainer}>
            <img className={classes.heroPhones} src="/img/home-page/hero-phones.png" alt="" />
          </div>
        </HeroContainerNew>
        <div className={classes.section}>
          <div className={classes.sectionContent}>
            <div className={classes.hackCarouselContainer}>
              <Typography className={classes.libraryTitle} variant="h2">{t('landingPage.libraryTitle')}</Typography>
              <Typography className={classes.libraryText}>
                {t('landingPage.libraryText')}
              </Typography>
              <img className={classes.libraryImage} src="/img/home-page/phones.png" alt="" />
              <Button
                className={classes.downloadButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                  document.querySelector('#business-form').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {t('landingPage.downloadCTA')}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.sectionWhite}>
          <div className={classes.sectionWhiteContent}>
            <Typography className={classes.libraryTitle} variant="h2">
              {t('landingPage.section2.title')}
            </Typography>
            <Typography className={classes.libraryText}>
              {t('landingPage.section2.text')}
            </Typography>
            <div className={classes.statsContainer}>
              <div>
                <Typography className={classes.statTitle}>
                  {t('landingPage.section2.stat1.title')}
                </Typography>
                <Typography className={classes.statText}>
                  {t('landingPage.section2.stat1.text')}
                </Typography>
              </div>
              <div>
                <Typography className={classes.statTitle}>
                  {t('landingPage.section2.stat2.title')}
                </Typography>
                <Typography className={classes.statText}>
                  {t('landingPage.section2.stat2.text')}
                </Typography>
              </div>
              <div>
                <Typography className={classes.statTitle}>
                  {t('landingPage.section2.stat3.title')}
                </Typography>
                <Typography className={classes.statText}>
                  {t('landingPage.section2.stat3.text')}
                </Typography>
              </div>
            </div>
            <img className={classes.hacksImage} src="/img/home-page/hacks.png" alt="" />
            <div className={classes.brandsContainer}>
              <img className={classes.brandImage} src="/img/home-page/brands/prime-video.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/google.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/audible.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/coursera.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/future-learn.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/mit.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/harvard.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/hulu.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/netflix.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/youtube.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/spotify.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/apple-podcasts.svg" />
              <img className={classes.brandImage} src="/img/home-page/brands/penguin.svg" />
            </div>
            <Button
              className={classes.downloadButton}
              variant="contained"
              color="secondary"
              onClick={() => {
                document.querySelector('#business-form').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {t('landingPage.downloadCTA')}
            </Button>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionContent}>
            <div className={classes.section3Container}>
              <div className={classes.section3Inner}>
                <Typography className={classes.libraryTitle} align="left" variant="h2">
                  {t('landingPage.section3.title')}
                </Typography>
                <Typography className={classes.libraryText} style={{ marginBottom: '48px', }}>
                  {t('landingPage.section3.text')}
                </Typography>
                <Button
                  className={classes.downloadButton}
                  style={{ marginBottom: 0 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    document.querySelector('#business-form').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  {t('landingPage.downloadCTA')}
                </Button>
              </div>
              <img className={classes.section3Image} src="/img/home-page/3d-rocket.png" alt="" />
            </div>
          </div>
        </div>
        <div className={classes.sectionNavy}>
          <div className={classes.sectionNavyContent}>
            <Typography className={classes.libraryTitle} variant="h2" style={{ color: '#fff', textAlign: 'center', marginBottom: '48px', }}>
              {t('landingPage.section4.title')}
            </Typography>
            <IframeResizer
              src="https://widget.senja.io/widget/aafde1d6-572c-49b1-8535-1f39c2fd8f7f"
              style={{ width: '1px', minWidth: '100%', border: 0, marginBottom: '48px', }}
            />
            <IframeResizer
              src="https://widget.senja.io/widget/11e69784-4d8c-4e92-8ba3-355bb1e9e37d"
              style={{ width: '1px', minWidth: '100%', border: 0, marginBottom: '48px', }}
            />
            <img className={classes.accolades} src="/img/home-page/accolades.svg" alt="" />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionContent}>
            <Typography className={classes.libraryTitle} variant="h2" style={{ textAlign: 'center', marginBottom: '48px', }}>
              {t('landingPage.section5.title')}
            </Typography>
            <FeaturesListMobile items={items} />
            <FeaturesList items={items} />
            <Typography className={classes.section6Title} variant="h2">
              <Trans
                i18nKey="landingPage.section6.title"
                components={{
                  l1: (
                    <a className={classes.section6Link} href="https://to.uptime.app/S5iL3GrK0cb" target="_blank" rel="noopener noreferrer" />
                  ),
                }}
              />
            </Typography>
            <div className={classes.section6Buttons}>
              <a
                onClick={trackCriteoButtonClick}
                href="https://uptime.app.link/S5iL3GrK0cb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/home-page/apple-button.svg" alt="" />
              </a>
              <a
                onClick={trackCriteoButtonClick}
                href="https://uptime.app.link/S5iL3GrK0cb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/img/home-page/google-button.svg" alt="" />
              </a>
            </div>
            <img className={classes.accolades} src="/img/home-page/accolades-2.svg" alt="" />
          </div>
        </div>
        <div className={classes.sectionWhite}>
          <div className={classes.sectionWhiteContent}>
            <Typography className={classes.libraryTitle} variant="h2" style={{ textAlign: 'center', marginBottom: '24px', }}>
              {t('landingPage.section7.title')}
            </Typography>
            <Typography className={classes.libraryText} style={{ marginBottom: '96px', }}>
              {t('landingPage.section7.text')}
            </Typography>
          </div>
        </div>
        <div className={classes.sectionNavyLight} style={{ position: 'relative', zIndex: '10', }}>
          <div className={classes.sectionNavyLightContent}>
            <div className={classes.section8Container}>
              <div className={classes.section8ContainerText}>
                <Typography className={classes.section8Title} variant="h2" style={{ textAlign: 'left', marginBottom: '12px', }}>
                  {t('landingPage.section8.title')}
                </Typography>
                <Typography className={classes.section8Text} style={{ marginBottom: '0', }}>
                  {t('landingPage.section8.text')}
                </Typography>
              </div>
              <div className={classes.section8ContainerForm}>
                <BusinessEnquiryForm id="business-form" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer paddingTop="270px" />
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  query LandingPageQueryEn($id: String, $language: String) {
    prismicLandingV3(id: { eq: $id }) {
      _previewable
      id
      prismicId
      type
      lang
      first_publication_date
      last_publication_date
      data {
        hero_title {
          text
        }
        hero_text {
          text
        }
        hero_image_left {
          alt
          gatsbyImageData
        }
        hero_image_right {
          alt
          gatsbyImageData(width: 226, imgixParams: { lossless: true })
        }
        library_title {
          text
        }
        app_highlights {
          title {
            text
          }
          text {
            text
          }
          image {
            alt
            gatsbyImageData(width: 454, imgixParams: { lossless: true })
          }
        }
        screenshots_title {
          text
        }
        screenshot_carousel {
          text {
            text
          }
          title {
            text
          }
          image {
            alt
            gatsbyImageData(width: 245, imgixParams: { lossless: true })
          }
        }
        title {
          text
        }
        subtitle {
          text
        }
        testimonials_title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
