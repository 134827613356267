import React, { FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      [theme.breakpoints.up(1008)]: {
        display: 'none',
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 72,
      textAlign: 'left',
      '& h3': {
        fontSize: 24,
        lineHeight: '30px',
        marginBottom: 16,
        color: theme.palette.primary.main,
      },
      '& p': {
        fontSize: 17,
        lineHeight: '24px',
        color: theme.palette.primary.main,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    imageContainer: {
      width: '100%',
      marginBottom: 25,
    },
    image: {
      display: 'block',
      width: '100%',
      maxWidth: '600px',
    },
    textContainer: {
    },
  };
});

const FeaturesListMobile: FC<any> = ({ items = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        return (
          <div className={classes.item} key={t(`landingPage.appHighlights.${index + 1}.title`)}>
            <div className={classes.imageContainer}>
              <img className={classes.image} src={item.image} alt="" />
            </div>
            <div className={classes.textContainer}>
              <Typography variant="h3">{t(`landingPage.appHighlights.${index + 1}.title`)}</Typography>
              <Typography variant="body1">{t(`landingPage.appHighlights.${index + 1}.text`)}</Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturesListMobile;
