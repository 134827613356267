import React, { FC } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../theme';
import '../fonts/fonts.css';
import SEOHelmet from '../helmet/SEOHelmet';

const Layout: FC<any> = ({
  children,
  location,
  pageContext,
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SEOHelmet location={location} locale={pageContext?.language} />
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
          {children}
        </div>
        <CssBaseline />
      </ThemeProvider>
    </>
  );
}

export default Layout;