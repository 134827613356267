import React, { FC } from 'react';
import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      margin: '0 8px',
      fontSize: 13,
      lineHeight: 1.38,
      fontWeight: 700,
      letterSpacing: '3%',
      textTransform: 'uppercase',
      padding: 7,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    flag: {
      display: 'block',
      marginRight: 4,
    },
    drawerTitle: {
      fontSize: 21,
      lineHeight: 30 / 21,
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginBottom: 24,
    },
    selectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: 4,
      boxSizing: 'border-box',
      backgroundColor: '#1F27440D',
      borderRadius: 8,
      marginBottom: 24,
    },
    selectOption: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: 109,
      height: 32,
      cursor: 'pointer',
    },
    selectOptionSelected: {
      background: '#fff',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
      borderRadius: 6,
    },
    selectOptionImage: {
      display: 'block',
      marginRight: 4,
    },
    selectOptionText: {
      color: theme.palette.primary.main,
      fontSize: 13,
      lineHeight: 16 / 13,
    },
    confirmButton: {
      justifyContent: 'center',
      marginBottom: 16,
    },
    cancelButton: {
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
  };
});

export type CurrencySelectorProps = {
  value: string;
  onChange: (currency: string) => void;
  currencies: any[]
};

const CurrencySelector: FC<CurrencySelectorProps> = ({ value, onChange, currencies = [] }) => {
  const classes = useStyles();
  return (
    <div className={classes.selectContainer}>
      {currencies.map((currency) => {
        return (
          <div
            key={currency.value}
            className={classnames(
              classes.selectOption,
              value === currency.value && classes.selectOptionSelected,
            )}
            onClick={() => {
              onChange(currency.value);
            }}
          >
            <img
              className={classes.selectOptionImage}
              src={currency.img}
              alt={`${currency.text} flag`}
            />
            <span className={classes.selectOptionText}>{currency.text}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CurrencySelector;
