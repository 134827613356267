import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Button, Theme } from '@material-ui/core';
import CloseIcon from '../icons/CloseIcon';
import { H4 } from '../Typography/Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme: Theme): any => {
  return {
    root: {
      display: ({ open }: any) => (open ? 'flex' : 'none'),
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#1F2744',
      color: '#fff',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '70px',
      padding: '28px 24px 0',
    },
    logo: {
      display: 'block',
      width: 107,
      [theme.breakpoints.up(600)]: {
        width: 134,
      },
    },
    textButton: {
      display: 'block',
      minWidth: 'unset',
      color: '#fff',
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: '500',
      padding: 0,
      marginBottom: '28px',
    },
    closeButtonContainer: {
      minWidth: 'unset',
      padding: 0,
    },
    closeButton: {
      color: '#fff',
      fontSize: 20,
    },
    content: {
      flexGrow: 1,
      padding: '0 24px',
    },
    footer: {
      padding: '32px 24px 92px',
      backgroundColor: '#0E1631',
    },
    footerTitle: {
      marginBottom: '13px',
    },
    footerLink: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '32px',
      fontWeight: '400',
      color: '#fff',
      '&:last-of-type': {
        marginBottom: '32px',
      },
    },
    socials: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    followLink: {
      display: 'block',
      marginRight: '12px',
      height: '24px',
      opacity: '0.4',
      '&:last-child': {
        marginRight: 0,
      },
    },
  };
});

const BurgerMenu = ({ open, onClose }: any) => {
  const classes = useStyles({ open }) as any;
  const { t } = useTranslation();
  const node = useRef();
  const links = t('header.burgerMenu.links').split('|');
  const footerLinks = t('header.burgerMenu.footer.links').split('|');
  useEffect(() => {
    const listener = (event: any) => {
      if (!node.current || (node.current as any).contains(event.target)) {
        return;
      }
      onClose(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [node, onClose]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (open) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'visible';
      }
    }
  }, [open]);
  return (
    <div className={classes.root} ref={node as any}>
      <div className={classes.header}>
        <img className={classes.logo} src="/img/uptime-logo-dragonfruit.svg" alt={t('header.logo.altText')} />
        <Button className={classes.closeButtonContainer} variant="text" onClick={onClose}>
          <CloseIcon className={classes.closeButton} />
        </Button>
      </div>
      <div className={classes.content}>
        <Button
          className={classes.textButton}
          variant="text"
          color="primary"
          component={Link}
          to="/creators"
          onClick={onClose}
        >
          {links[0]}
        </Button>
        <Button
          className={classes.textButton}
          variant="text"
          color="primary"
          component={Link}
          href="https://web.uptime.app/login/standalone"
          onClick={onClose}
        >
          {links[1]}
        </Button>
        <Button
          className={classes.textButton}
          variant="text"
          color="primary"
          onClick={() => {
            const form = document.querySelector('#business-form');
            if (form) {
              onClose();
              form.scrollIntoView({ behavior: 'smooth' })
            } else {
              window.location.href = '/';
            }
          }}
        >
          {links[2]}
        </Button>
      </div>
      <div className={classes.footer}>
        <H4 className={classes.footerTitle}>{t('header.burgerMenu.footer.title')}</H4>
        <a href="mailto:info@uptime.app" className={classes.footerLink}>
          {footerLinks[0]}
        </a>
        <a href="https://help.uptime.app/en/" target="_blank" rel="noopener noreferrer" className={classes.footerLink}>
          {footerLinks[1]}
        </a>
        <div className={classes.socials}>
          <a
            className={classes.followLink}
            href="https://www.instagram.com/uptime.app/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/img/instagram.svg" alt="Instagram" />
          </a>
          <a
            className={classes.followLink}
            href="https://www.facebook.com/Uptime-109826557071160/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/img/facebook.svg" alt="Facebook" />
          </a>
          <a
            className={classes.followLink}
            href="https://twitter.com/uptimeapp?lang=en"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/img/twitter.svg" alt="Twitter" />
          </a>
          <a
            className={classes.followLink}
            href="https://www.linkedin.com/company/uptime.app/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/img/linkedin.svg" alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
