import React from 'react';

const AppleAwardIcon = ({ color = '#767676' }) => {
  return (
    <svg width="73" height="35" viewBox="0 0 73 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.9836 14.6239C43.0203 18.553 46.4485 19.8605 46.4864 19.8771C46.4575 19.9694 45.9387 21.7405 44.6803 23.5699C43.5925 25.1515 42.4635 26.7273 40.685 26.76C38.9375 26.792 38.3756 25.729 36.3776 25.729C34.3803 25.729 33.756 26.7273 32.1017 26.792C30.385 26.8566 29.0778 25.0817 27.9809 23.5058C25.7397 20.2824 24.0269 14.3973 26.3267 10.4247C27.4692 8.45185 29.511 7.20259 31.7271 7.17055C33.4129 7.13857 35.004 8.29877 36.0345 8.29877C37.0644 8.29877 38.998 6.90352 41.0307 7.10843C41.8817 7.14367 44.2703 7.45038 45.8041 9.68383C45.6805 9.76004 42.954 11.3391 42.9836 14.6239V14.6239ZM39.6993 4.97587C40.6107 3.87839 41.2241 2.35059 41.0568 0.830444C39.7431 0.88297 38.1545 1.70132 37.2122 2.7982C36.3677 3.76953 35.6281 5.32422 35.8277 6.81428C37.292 6.92698 38.7879 6.07405 39.6993 4.97587" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25555 4.62946C7.32595 0.139883 11.2829 0 11.2829 0C11.2829 0 11.4873 4.17275 7.25555 4.62946ZM3.65999 1.90054C3.65999 1.90054 7.02116 4.10958 4.95683 8.03474C1.50742 5.40777 3.65999 1.90054 3.65999 1.90054ZM3.48936 13.1307C-0.299356 11.0886 1.32293 7.27599 1.32293 7.27599C1.32293 7.27599 4.96305 8.91926 3.48936 13.1307ZM3.7038 18.1615C-0.483763 17.3701 0.0188313 13.2246 0.0188313 13.2246C0.0188313 13.2246 3.95424 13.6794 3.7038 18.1615ZM5.10384 23.3942C0.867822 23.8131 0.302191 19.6769 0.302191 19.6769C0.302191 19.6769 4.20979 19.005 5.10384 23.3942ZM8.16294 27.5134C4.30038 29.3962 2.46298 25.6942 2.46298 25.6942C2.46298 25.6942 5.9358 23.6885 8.16294 27.5134ZM11.7098 31.1167C8.27244 33.7619 5.78279 30.5121 5.78279 30.5121C5.78279 30.5121 8.81599 27.8264 11.7098 31.1167ZM15.3178 33.0487C12.6762 36.5683 9.45351 34.14 9.45351 34.14C9.45351 34.14 11.6952 30.6957 15.3178 33.0487ZM5.91981 8.35717C7.11211 4.04727 10.9636 5.01617 10.9636 5.01617C10.9636 5.01617 10.1153 9.09789 5.91981 8.35717ZM9.12941 9.17395C9.12941 9.17395 8.97831 13.3487 4.7251 13.4038C5.17633 8.93922 9.12941 9.17395 9.12941 9.17395ZM4.93217 18.2127C4.51679 13.7443 8.43258 13.1281 8.43258 13.1281C8.43258 13.1281 9.08697 17.2486 4.93217 18.2127ZM6.328 23.0044C4.93129 18.7634 8.60042 17.1953 8.60042 17.1953C8.60042 17.1953 10.1526 21.0386 6.328 23.0044ZM9.79183 20.6419C9.79183 20.6419 12.0865 24.0463 8.74803 26.8294C6.52524 23.001 9.79183 20.6419 9.79183 20.6419ZM12.1608 23.3722C12.1608 23.3722 15.0104 26.2722 12.2149 29.6562C9.36191 26.3261 12.1608 23.3722 12.1608 23.3722ZM14.5951 25.992C14.5951 25.992 17.947 28.2141 15.8678 32.1313C12.4286 29.4895 14.5951 25.992 14.5951 25.992Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M64.751 4.62946C64.6806 0.139883 60.7236 0 60.7236 0C60.7236 0 60.5193 4.17275 64.751 4.62946ZM68.3478 1.90054C68.3478 1.90054 64.9867 4.10958 67.051 8.03474C70.5004 5.40777 68.3478 1.90054 68.3478 1.90054ZM68.5186 13.1307C72.3073 11.0886 70.685 7.27599 70.685 7.27599C70.685 7.27599 67.0449 8.91926 68.5186 13.1307ZM68.304 18.1615C72.4916 17.3701 71.989 13.2246 71.989 13.2246C71.989 13.2246 68.0536 13.6794 68.304 18.1615ZM66.903 23.3942C71.139 23.8131 71.7046 19.6769 71.7046 19.6769C71.7046 19.6769 67.797 19.005 66.903 23.3942ZM63.8452 27.5132C67.7077 29.3961 69.5451 25.694 69.5451 25.694C69.5451 25.694 66.0723 23.6884 63.8452 27.5132ZM60.2983 31.1166C63.7357 33.7618 66.2253 30.512 66.2253 30.512C66.2253 30.512 63.1921 27.8263 60.2983 31.1166ZM56.6898 33.0487C59.3314 36.5683 62.5541 34.14 62.5541 34.14C62.5541 34.14 60.3124 30.6957 56.6898 33.0487ZM66.0823 8.35704C64.89 4.04714 61.0385 5.01604 61.0385 5.01604C61.0385 5.01604 61.8868 9.09776 66.0823 8.35704ZM62.8779 9.17395C62.8779 9.17395 63.029 13.3487 67.2822 13.4038C66.831 8.93922 62.8779 9.17395 62.8779 9.17395ZM67.0757 18.2126C67.491 13.7441 63.5753 13.1279 63.5753 13.1279C63.5753 13.1279 62.9209 17.2485 67.0757 18.2126ZM65.6788 23.0044C67.0755 18.7634 63.4064 17.1953 63.4064 17.1953C63.4064 17.1953 61.8542 21.0386 65.6788 23.0044ZM62.2151 20.6417C62.2151 20.6417 59.9204 24.0462 63.2589 26.8292C65.4817 23.0008 62.2151 20.6417 62.2151 20.6417ZM59.8473 23.3722C59.8473 23.3722 56.9977 26.2722 59.7932 29.6562C62.6462 26.3261 59.8473 23.3722 59.8473 23.3722ZM57.4123 25.992C57.4123 25.992 54.0605 28.2141 56.1396 32.1313C59.5788 29.4895 57.4123 25.992 57.4123 25.992Z" fill={color} />
    </svg>
  )
}

export default AppleAwardIcon;
