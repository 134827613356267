import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      zIndex: 2,
      backgroundColor: '#03091D',
      color: '#fff',
    },
    inner: {
      maxWidth: '600px',
      width: '100%',
      margin: '0 auto',
      padding: '40px 24px 24px',
      [theme.breakpoints.up(1054)]: {
        maxWidth: '1006px',
        padding: '72px 0 32px',
      },
    },
    gridItem: {
      marginBottom: '32px',
      '&:last-child': {
        marginBottom: '0',
      },
    },
    disclaimerContainer: {
      padding: '0 24px 24px',
      maxWidth: '600px',
      margin: '0 auto',
      [theme.breakpoints.up(1054)]: {
        maxWidth: '1006px',
        padding: '0 0 72px',
      },
    },
    title: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      marginBottom: '4px',
    },
    link: {
      display: 'block',
      width: '100%',
      fontSize: '16px',
      lineHeight: '23px',
      textDecoration: 'none',
      color: '#fff',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    linkBold: {
      display: 'block',
      width: '100%',
      fontSize: '16px',
      lineHeight: '32px',
      fontWeight: '500',
      textDecoration: 'none',
      color: '#fff',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    copyright: {
      display: 'block',
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: '400',
    },
  };
});

const LegalFooter = ({
  supportEmail = 'support@uptime.app',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const links = t('legalFooter.links').split('|');
  const now = new Date();
  return (
    <footer className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.gridItem}>
          <Typography className={classes.title} variant="span">
            {t('footer.getInTouch.title')}
          </Typography>
          <a href={`mailto:${supportEmail}`} className={classes.link}>
            <Typography className={classes.link} variant="span">
              {links[0]}
            </Typography>
          </a>
        </div>
        <div className={classes.gridItem}>
          <Link className={classes.linkBold} to="/terms-and-conditions">
            {links[1]}
          </Link>
          <Link className={classes.linkBold} to="/privacy-policy">
            {links[2]}
          </Link>
          <Link className={classes.linkBold} to="/cookie-policy">
            {links[3]}
          </Link>
        </div>
      </div>
      <div className={classes.disclaimerContainer}>
        <span className={classes.copyright}>&copy; {now.getFullYear()} {t('footer.copyright')}</span>
      </div>
    </footer>
  );
};

export default LegalFooter;
