import React from 'react';
import { makeStyles } from '@material-ui/core';
import { tan6 } from '../../utils/utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IframeResizer from 'iframe-resizer-react';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      transform: 'skewY(-6.733deg)',
      marginTop: `calc((100vw * ${tan6} * -1) / 2)`,
      marginBottom: '32px',
      padding: `calc((100vw * ${tan6} * -1) / 2) 0`,
      borderRadius: '25px 50px 25px 50px',
      [theme.breakpoints.up(1170)]: {
        transform: 'skewY(6.733deg)',
        marginBottom: `calc((1170px * ${tan6} * -1) / 2)`,
        marginTop: `calc((1170px * ${tan6} * -1) / 2)`,
        padding: `calc((1170px * ${tan6} * -1) / 2) 0`,
        borderRadius: '50px 25px 50px 25px',
      },
    },
    content: {
      transform: 'skewY(6.733deg)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '64px 0 46px',
      margin: '0 auto',
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.up(1170)]: {
        transform: 'skewY(-6.733deg)',
      },
    },
    container: {
      width: '100%',
      maxWidth: '600px',
      padding: '0 24px',
      marginBottom: '72px',
      [theme.breakpoints.up(1170)]: {
        maxWidth: '1218px',
      },
    },
    awardsImage: {
      display: 'none',
      [theme.breakpoints.up(1200)]: {
        display: 'block',
        marginTop: '72px',
      }
    }
  }
})

const CreatorsTestimonialSection = ({
  awardsImage,
}) => {
  const classes = useStyles();
  const image = getImage(awardsImage);
  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <div className={classes.container}>
          <IframeResizer
            src="https://widget.senja.io/widget/82fec344-13f1-45ca-a1a0-76a594742b38"
            style={{ width: '1px', minWidth: '100%', border: 0 }}
          />
        </div>
        <IframeResizer
          src="https://widget.senja.io/widget/24e6ebdf-f2ae-4045-bc7e-81bf85d1728c"
          style={{ width: '1px', minWidth: '100%', border: 0 }}
        />
        <GatsbyImage className={classes.awardsImage} image={image} alt="" />
      </div>
    </section>
  )
}

export default CreatorsTestimonialSection;
