import createMuiTheme from '@material-ui/core/styles/createTheme';
import createPalette from '@material-ui/core/styles/createPalette';

const titleFontFamily = 'Rubik, sans-serif';
const newBodyFontFamily = 'Rubik, sans-serif';

/* Material-ui theme */
const palette = createPalette({
  primary: {
    main: '#1F2644',
    dark: '#562b97',
    light: '#ede3ff',
  },
  secondary: {
    main: '#EA0053'
  },
  text: {
    primary: '#1F2744',
    secondary: '#fff',
  },
  background: {
    default: '#fff'
  }
});

const theme = createMuiTheme(
  ({
    palette,
    // $FlowFixMe --> Reason: theme options typography bad defined
    typography: {
      h1: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '6.75rem',
        fontWeight: 700,
        lineHeight: 1.05,
        color: palette.text.primary
      },
      h2: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '3rem',
        fontWeight: 700,
        lineHeight: 1.1,
        color: palette.text.secondary
      },
      h3: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontWeight: 700,
        fontSize: '2rem',
        color: palette.text.primary
      },
      h4: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontWeight: 700,
        fontSize: '1.3rem',
        color: palette.text.primary
      },
      h5: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontWeight: 700,
      },
      h6: {
        fontFamily: newBodyFontFamily,
        fontDisplay: 'swap',
        color: palette.text.secondary,
        marginBottom: '1.25rem',
      },
      subtitle1: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.5625rem',
      },
      subtitle2: {
        fontFamily: newBodyFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.25rem',
      },
      body1: {
        fontFamily: newBodyFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.05rem',
        color: palette.text.primary,
        lineHeight: 1.625
      },
      caption: {
        fontFamily: newBodyFontFamily,
        fontDisplay: 'swap',
      },
      button: {
        fontFamily: titleFontFamily,
        fontWeight: 'bold',
        fontDisplay: 'swap',
        textTransform: 'none',
        fontSize: '1rem',
      },
      overline: {
        fontFamily: newBodyFontFamily,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          letterSpacing: '0',
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            fontFamily: newBodyFontFamily,
            backgroundColor: '#f8f8f8',
          },
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
          color: palette.primary.main,
        }
      }
    },
  }),
);

export default theme;
