import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/gatsby';

const restrictedBranchParams = [
  '~stage',
  '~creation_source',
  '+match_guaranteed',
  '+is_first_session',
  '+click_timestamp',
]

export async function getBranchLinkParams(branchMatchId) {
  const isTest = process.env.GATSBY_STAGE !== 'prod';
  const branchKey = !isTest ? process.env.GATSBY_BRANCH_KEY_PROD : process.env.GATSBY_BRANCH_KEY_TEST;
  const baseUrl = !isTest ? '/prod-uptime-global-api/branch/proxy/v1' : '/staging-uptime-global-api/branch/proxy/v1';
  return fetch(`${baseUrl}/open`, {
    method: 'POST',
    body: JSON.stringify({
      browser_fingerprint_id: branchMatchId,
      link_identifier: branchMatchId,
      options: {},
      current_url: location.href,
      branch_key: branchKey,
      sdk: 'web2.85.0'
    })
  })
    .then((resp) => resp.json())
    .then((jsonResp) => {
      return JSON.parse(jsonResp.data);
    })
    .catch((err) => {
      console.log(err);
    })
}

const useBranchLink = (linkParams?: any, linkOverride?: string) => {
  const [linkData, setLinkData] = useState<any>();
  const isBrowser = typeof window !== 'undefined';
  const queryParams = isBrowser && new URLSearchParams(window.location.search);
  const isTest = process.env.GATSBY_STAGE !== 'prod';
  const [branchLink, setBranchLink] = useState('');

  useEffect(() => {
    if (!isBrowser || !queryParams?.get('_branch_match_id')) {
      if (linkParams) {
        setLinkData(linkParams);
      } else {
        setBranchLink(linkOverride || 'https://uptime.app.link/S5iL3GrK0cb');
      }
      return;
    }
    if (linkOverride) {
      setBranchLink(linkOverride);
      return;
    }
    const branchKey = !isTest ? process.env.GATSBY_BRANCH_KEY_PROD : process.env.GATSBY_BRANCH_KEY_TEST;
    const baseUrl = !isTest ? '/prod-uptime-global-api/branch/proxy/v1' : '/staging-uptime-global-api/branch/proxy/v1';
    fetch(`${baseUrl}/open`, {
      method: 'POST',
      body: JSON.stringify({
        browser_fingerprint_id: queryParams.get('_branch_match_id'),
        link_identifier: queryParams.get('_branch_match_id'),
        options: {},
        current_url: location.href,
        branch_key: branchKey,
        sdk: 'web2.85.0'
      })
    })
      .then((resp) => resp.json())
      .then((jsonResp) => {
        setLinkData(JSON.parse(jsonResp.data));
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      })
  }, [isBrowser, isTest, linkOverride]);

  useEffect(() => {
    if (!isBrowser || !linkData) {
      return;
    }
    const branchKey = !isTest ? process.env.GATSBY_BRANCH_KEY_PROD : process.env.GATSBY_BRANCH_KEY_TEST;
    const baseUrl = !isTest ? '/prod-uptime-global-api/branch/proxy/v1' : '/staging-uptime-global-api/branch/proxy/v1';
    const filteredLinkData = Object.keys(linkData)
      .filter((key) => !restrictedBranchParams.includes(key))
      .reduce((prev, curr) => {
        if (linkData[curr] !== null) {
          prev[curr] = linkData[curr];
        }
        return prev;
      }, {});
    fetch(`${baseUrl}/url`, {
      method: 'POST',
      body: JSON.stringify({
        branch_key: branchKey,
        campaign: linkData['~campaign'] || 'Website QR Code',
        channel: linkData['~channel'] || 'Website',
        feature: linkData['~feature'] || 'QR Code',
        tags: linkData['~tags'],
        stage: linkData['~stage'],
        data: {
          ...filteredLinkData,
          $fallback_url: undefined,
          skip_browser_redirect: true,
        },
      })
    })
      .then((resp) => {
        if (!resp.ok) {
          throw new Error(`Error when fecthing branch url: status code: ${resp.status}`);
        }
        return resp.json();
      })
      .then((link) => {
        setBranchLink(link.url);
      })
      .catch((err) => {
        Sentry.captureException(err);
        const domain = !isTest ? 'uptime.app.link' : 'uptime.test-app.link';
        setBranchLink(`https://${domain}/?${Object.entries({ ...linkData, $fallback_url: undefined })
          .map(([k, v]) => `${k}=${v}`)
          .join('&')}`
        );
      });
  }, [isBrowser, isTest, linkData]);

  return {
    branchLink,
    linkData,
  };
}

export default useBranchLink;
